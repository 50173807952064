

















































































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */

import {Component, Prop, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import Promotion from "@/models/Promotion";
import {FILTER} from "@/store";

@Component({})
export default class FilterButton extends Vue {
    @Prop() promo: boolean;
    @Prop() lot: boolean;
    @Prop() budget: boolean;
    @Prop() dateInterval: boolean;
    @Prop() propAvailable: boolean;
    @Prop() invoiceStatus: boolean;
    @Prop() paymentStatus: boolean;
    public count = 0;
    public promotions: Promotion[] = [];

    initFilter(): void {
        this.$store.commit('resetFilter');
        this.load();
    }

    setCount(): void {
        this.count = 0;
        // const p = ["searched", "promotion", "available", "unavailable", "success", "failed", "ilot", "lot", "context",
        //     "budgetMin", "budgetMax", "page", "perPage", "payed", "waiting", "penality", "dateMin", "dateMax"];
        // if (this.promo && this.$store.state.filter.promotion !== FILTER.promotion) {
        //     this.count++;
        // }
        // if (this.lot && this.$store.state.filter.ilot !== FILTER.ilot) {
        //     this.count++;
        // }
        // if (this.lot && this.$store.state.filter.lot !== FILTER.lot) {
        //     this.count++;
        // }
        // if (this.budget && this.$store.state.filter.budgetMin !== FILTER.budgetMin) {
        //     this.count++;
        // }
        // if (this.budget && this.$store.state.filter.budgetMax !== FILTER.budgetMax) {
        //     this.count++;
        // }
        Object.keys(FILTER).forEach((key) => {
            if (FILTER[key] !== this.$store.state.filter[key]) {
                this.count++;
            }
        });
    }

    async mounted(): Promise<void> {
        this.$store.getters.getFilter();
        this.setCount();
        this.promotions = await api.getPromotions();
    }

    load(): void {
        if (this.$refs.hasOwnProperty('filter')) {
            (this.$refs.filter as any).hide(true);
        }
        this.$store.commit('setFilter', this.$store.state.filter);
        this.setCount();
        this.$emit('load');
        this.$forceUpdate();
    }
}
