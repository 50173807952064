/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
export default class PaymentExtend {
    subscriptionCount = 0;
    finishedSubscriptionCount = 0;
    runningSubscriptionCount = 0;

    constructor(object?: any) {
        if (object) {
            this.subscriptionCount = object.subscriptionCount;
            this.finishedSubscriptionCount = object.finishedSubscriptionCount;
            this.runningSubscriptionCount = object.runningSubscriptionCount;
        }
    }
}
