













import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class Loading extends Vue {
    @Prop() width: string;
    @Prop() background: string;

}
