
































import {Component, Vue} from 'vue-property-decorator';

@Component({})
export default class SaleBar extends Vue {
    public chartOptions = {};
    public series = [
        {
            name: "Net Profit",
            data: [40, 40, 30, 30, 35, 35, 50]
        }
    ];

    mounted(): void {
        this.chartOptions = {
            chart: {
                type: "area",
                height: 150,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                type: "solid",
                opacity: 1
            },
            stroke: {
                curve: "smooth",
                show: true,
                width: 3,
                colors: ['#1BC5BD']
            },
            xaxis: {
                categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: false,
                    style: {
                        colors: '#B5B5C3',
                        fontSize: "12px",
                        fontFamily: 'Poppins'
                    }
                },
                crosshairs: {
                    show: false,
                    position: "front",
                    stroke: {
                        color: '#E5EAEE',
                        width: 1,
                        dashArray: 3
                    }
                },
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                min: 0,
                max: 55,
                show: false,
                labels: {
                    show: false,
                    style: {
                        colors: '#B5B5C3',
                        fontSize: "12px",
                        fontFamily: 'Poppins'
                    }
                }
            },
            states: {
                normal: {
                    filter: {
                        type: "none",
                        value: 0
                    }
                },
                hover: {
                    filter: {
                        type: "none",
                        value: 0
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: "none",
                        value: 0
                    }
                }
            },
            tooltip: {
                style: {
                    fontSize: "12px",
                    fontFamily: 'Poppins'
                },
                y: {
                    formatter: function (val: number) {
                        return "$" + val + " thousands";
                    }
                }
            },
            colors: ['#C9F7F5'],
            markers: {
                colors: ['#C9F7F5'],
                strokeColor: ['#1BC5BD'],
                strokeWidth: 3
            },
            grid: {
                show: false,
                padding: {
                    left: 0,
                    right: 0
                }
            }
        };
    }
}
