import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

import Dashboard from "@/views/admin/Dashboard.vue";
import Login from "@/views/Login.vue";
import promotionList from "@/views/admin/promotion/promotionList.vue";
import promotionForm from "@/views/admin/promotion/promotionForm.vue";
import subscriptionList from "@/views/admin/subscription/subscriptionList.vue";
import subscriptionForm from "@/views/admin/subscription/subscriptionForm.vue";
import bienForm from "@/views/admin/biens/bienForm.vue";
import TypeForm from "@/views/admin/type-biens/TypeForm.vue";
import TypeList from "@/views/admin/type-biens/TypeList.vue";
import bienList from "@/views/admin/biens/bienList.vue";
import subscriptionShow from "@/views/admin/subscription/subscriptionShow.vue";
import invoiceList from "@/views/admin/invoice/invoiceList.vue";
import invoiceShow from "@/views/admin/invoice/invoiceShow.vue";
import paiementList from "@/views/admin/paiement/paiementList.vue";
import clientList from "@/views/admin/client/clientList.vue";
import clientShow from "@/views/admin/client/clientShow.vue";
import clientForm from "@/views/admin/client/clientForm.vue";
import stripeList from "@/views/admin/stripe/stripeList.vue";
import userList from "@/views/admin/user/userList.vue";
import userShow from "@/views/admin/user/userShow.vue";
import userForm from "@/views/admin/user/userForm.vue";
import Config from "@/views/admin/user/Config.vue";
import promotionShow from "@/views/admin/promotion/promotionShow.vue";
import card from "@/views/admin/biens/card.vue";


import MyAccountShow from "@/views/selfcare/account/MyAccountShow.vue";
import MyScheduler from "@/views/selfcare/invoice/MyScheduler.vue";
import MySubscriptionList from "@/views/selfcare/subscription/MySubscriptionList.vue";
import MySubscriptionForm from "@/views/selfcare/subscription/MySubscriptionForm.vue";
import bienShow from "@/views/admin/biens/bienShow.vue";
import contractEditor from "@/views/admin/contractEditor/contractEditor.vue";


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: 'login',
        component: Login,
    },


    {
        path: '/souscription/show/:uuid',
        name: 'souscription.show',
        component: subscriptionShow,
        meta: {requireAuth: true},
    },
    {
        path: '/souscription/form/:uuid?',
        name: 'souscription.form',
        component: subscriptionForm,
        meta: {requireAuth: true},
    },

    {
        path: '/invoice/show/:uuid',
        name: 'invoice.show',
        component: invoiceShow,
        meta: {requireAuth: true},
    },

    // ESPACE CLIENT
    {
        path: '/',
        name: 'mySubscriptions.list',
        component: MySubscriptionList,
        meta: {requireAuth: true, requireAdmin: false},
    },
    {
        path: '/mes/souscriptions',
        name: 'mySubscriptions.list',
        component: MySubscriptionList,
        meta: {requireAuth: true, requireAdmin: false},
    },
    {
        path: '/souscrire',
        name: 'mySubscriptions.form',
        component: MySubscriptionForm,
        meta: {requireAuth: true, requireAdmin: false},
    },
    // {
    //     path: '/ma/souscription/:uuid?',
    //     name: 'mySubscription.show',
    //     component: MySubscriptionShow,
    //     meta: {requireAuth: true, requireAdmin: false},
    // },

    {
        path: '/mes/factures',
        name: 'myInvoices.list',
        component: invoiceList,
        meta: {requireAuth: true, requireAdmin: false},
    },
    {
        path: '/mes/paiements',
        name: 'myPayments.list',
        component: paiementList,
        meta: {requireAuth: true, requireAdmin: false},
    },
    {
        path: '/mon/compte',
        name: 'myAccount.show',
        component: MyAccountShow,
        meta: {requireAuth: true, requireAdmin: false},
    },
    {
        path: '/mon/echeancier',
        name: 'myScheduler',
        component: MyScheduler,
        meta: {requireAuth: true, requireAdmin: false},
    },

    //ESPACE CLIENT


    //ADMIN

    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {requireAuth: true, requireAdmin: true},
    },

    {
        path: '/customer/list',
        name: 'customer.list',
        component: clientList,
        meta: {requireAuth: true, requireAdmin: true},
    },
    {
        path: '/customer/show/:uuid',
        name: 'customer.show',
        component: clientShow,
        meta: {requireAuth: true, requireAdmin: true},
    },
    {
        path: '/customer/form/:uuid?',
        name: 'customer.form',
        component: clientForm,
        meta: {requireAuth: true, requireAdmin: true},
    },


    {
        path: '/user/list',
        name: 'user.list',
        component: userList,
        meta: {requireAuth: true, requireAdmin: true},
    },
    {
        path: '/user/show/:uuid',
        name: 'user.show',
        component: userShow,
        meta: {requireAuth: true, requireAdmin: true},
    },
    {
        path: '/user/form/:uuid?',
        name: 'user.form',
        component: userForm,
        meta: {requireAuth: true, requireAdmin: true},
    },
    {
        path: '/promotion/contract/editor/:uuid',
        name: 'contract.editor',
        component: contractEditor,
        meta: {requireAuth: true, requireAdmin: true},
    },
    // {
    //     path: '/promotion/contract/origin/editor/:uuid',
    //     name: 'contract.originEditor',
    //     component: originEditor,
    //     meta: {requireAuth: true, requireAdmin: true},
    // },
    //
    // {
    //     path: '/promotion/contract/ckeditor/:uuid',
    //     name: 'contract.ckeditor',
    //     component: Ckeditor,
    //     meta: {requireAuth: true, requireAdmin: true},
    // },

    {
        path: '/bien/form/:uuid?',
        name: 'bien.form',
        component: bienForm,
        meta: {requireAuth: true, requireAdmin: true},
    },
    {
        path: '/bien/show/:uuid',
        name: 'bien.show',
        component: bienShow,
        meta: {requireAuth: true},
    },
    {
        path: '/bien/list',
        name: 'bien.list',
        component: bienList,
        meta: {requireAuth: true, requireAdmin: true},
    },
    {
        path: '/card',
        name: 'card',
        component: card,
        meta: {requireAuth: true, requireAdmin: true},
    },
    {
        path: '/type/list',
        name: 'type.list',
        component: TypeList,
        meta: {requireAuth: true, requireAdmin: true},
    },
    {
        path: '/type/form/:uuid?',
        name: 'type.form',
        component: TypeForm,
        meta: {requireAuth: true, requireAdmin: true},
    },
    {
        path: '/promotion/list',
        name: 'promotion.list',
        component: promotionList,
        meta: {requireAuth: true, requireAdmin: true},
    },
    {
        path: '/promotion/form/:uuid?',
        name: 'promotion.form',
        component: promotionForm,
        meta: {requireAuth: true, requireAdmin: true},
    },
    {
        path: '/promotion/show/:uuid',
        name: 'promotion.show',
        component: promotionShow,
        meta: {requireAuth: true, requireAdmin: true},
    },
    {
        path: '/souscription/list',
        name: 'souscription.list',
        component: subscriptionList,
        meta: {requireAuth: true, requireAdmin: true},
    },
    {
        path: '/souscription/property/form/:propertyUuid?',
        name: 'propertySubscription.form',
        component: subscriptionForm,
        meta: {requireAuth: true, requireAdmin: true},
    },

    {
        path: '/payment/list',
        name: 'payment.list',
        component: paiementList,
        meta: {requireAuth: true},
    },

    {
        path: '/invoice/show/:uuid',
        name: 'invoice.show',
        component: invoiceShow,
        meta: {requireAuth: true, requireAdmin: true},
    },

    {
        path: '/stripe/list',
        name: 'stripe.list',
        component: stripeList,
        meta: {requireAuth: true, requireAdmin: true},
    },

    {
        path: '/invoice/list',
        name: 'invoice.list',
        component: invoiceList,
        meta: {requireAuth: true, requireAdmin: true},
    },

    {
        path: '/config',
        name: 'config',
        component: Config,
        meta: {requireAuth: true, requireAdmin: true},
    },

    {
        path: '/invoice/show/:uuid',
        name: 'invoice.show',
        component: invoiceShow,
        meta: {requireAuth: true, requireAdmin: true},
    },
//ADMIN
//     {
//         path: '/about',
//         name: 'About',
//         // route level code-splitting
//         // this generates a separate chunk (about.[hash].js) for this route
//         // which is lazy-loaded when the route is visited.
//         component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//     }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
