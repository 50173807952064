








































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Person from "@/models/Person";

@Component({
    components: {
        Base
    }
})
export default class userForm extends Vue {


}
