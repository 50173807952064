








































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import PropertyType from "@/models/PropertyType";
import {api} from "@/services/Api";
import DocumentRequired from "@/models/DocumentRequired";
import Swal from 'sweetalert2';
import Popup from "@/models/Popup";

@Component({
    components: {
        Base
    }
})
export default class TypeForm extends Vue {
    public type = new PropertyType();

    async save() {
        if (this.isValid()) {
            const res = await api.post('api/property/type/new', this.type);
            console.log(res);
            if (res.data.status === 'success') {
                this.$router.push({name: 'type.list'});
            } else {
                Swal.fire(
                    'Erreur!',
                    'La création du type de bien a échouée.',
                    'error'
                );

            }
        }else{
            new Popup('Attention', 'Votre formulaire est incomplet', 'warning');
        }
    }

    addDocument() {

        for (let i = 0; i < this.type.documents.length; i++) {
            if (this.type.documents[i].title.trim().length === 0) {
                return;
            }
        }
        const doc = new DocumentRequired();
        this.type.documents.push(doc);
        // console.log(this.type);
    }

    removeDocument(doc: DocumentRequired) {
        const index = this.type.documents.findIndex((d) => {
            return d.title === doc.title;
        })
        this.type.documents.splice(index, 1);
    }

    isValid(): boolean {
        if (this.type.title) {
            return this.type.title.trim().length > 0;
        }
        return false;
    }

    mounted(): void {
        if (this.$route.params.hasOwnProperty('uuid')) {
            this.loadType(this.$route.params.uuid);
        }
    }

    async loadType(uuid: string) {
        this.$store.commit('load');
        const res = await api.get('api/property/type/' + uuid + '/get');
        this.type = new PropertyType(res.type);
        this.$store.commit('stopLoad');
    }
}
