















































































































































































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Promotion from "@/models/Promotion";
import {api} from "@/services/Api";
import Loading from "@/components/Loading.vue";
import Property from "@/models/Property";
import Payment from "@/models/Payment";
import Swal from "sweetalert2";
import Map from "@/components/Map.vue";
import BienTab from "@/views/admin/biens/bienTab.vue";
import ClientTab from "@/views/admin/client/clientTab.vue";
import PaiementTab from "@/views/admin/paiement/paiementTab.vue";

@Component({
    components: {
        PaiementTab,
        ClientTab,
        BienTab,
        Map,
        Loading,
        Base
    }
})
export default class promotionShow extends Vue {
    public promotion: Promotion | null = null;
    public properties: Property[] = [];
    public payments: Payment[] = [];
    public loading = false;
    public mapKey = 1;

    async mounted(): Promise<void> {
        this.load();
    }

    selectProperty(uuid) {
        console.log(uuid);
    }

    async loadProperties(): Promise<void> {
        const res = await api.get('api/promotion/properties/' + this.$route.params.uuid + '/get');
        res.properties.forEach((item: Property) => {
            this.properties.push(new Property(item));
        });
    }

    async loadPaiements(): Promise<void> {
        const res = await api.get('api/payment/promotion/' + this.$route.params.uuid + '/get');
        res.payments.forEach((item: Payment) => {
            this.payments.push(new Payment(item));
        });
    }

    async load(): Promise<void> {
        this.loading = true;
        // this.loadProperties();
        // this.loadPaiements();
        const res = await api.get('api/promotion/' + this.$route.params.uuid + '/get');
        this.promotion = new Promotion(res.promotion);
        this.$store.commit('resetFilter');
        this.$store.state.filter.promotion = {uuid: this.promotion.uuid, title: this.promotion.title};
        this.$store.commit('saveFilter');
        this.mapKey++;
        this.loading = false;
    }

    round(amount: number) {
        return Math.round(amount * 100) / 100;
    }

    async deleteBien(uuid: any) {
        Swal.fire({
            title: 'Bien',
            text: "Voulez-vous vraiment supprimer ce bien?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Non, annuler!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await api.delete('api/property/' + uuid + '/delete');
                if (res.data.status === 'success') {
                    this.load();
                    Swal.fire(
                        'Supprimé!',
                        'Bien supprimé avec succès.',
                        'success'
                    );


                }
            }
        })
    }
}
