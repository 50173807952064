






































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Loading from "@/components/Loading.vue";
import Swal from 'sweetalert2';


@Component({
    components: {
        Loading,
        Base
    }
})
export default class card extends Vue {

    serveur(x: any){
        Swal.fire({
            title: 'region '+x,
            html: `<ul class="list-group">
            <li class="list-group-item">Bien 1 - 123 m2 ** KOumassi sicogi</li>
            <li class="list-group-item">Bien 2 - 123 m2 ** KOumassi sicogi</li>
            <li class="list-group-item">Bien 3 - 123 m2 ** KOumassi sicogi</li>
            <li class="list-group-item">Bien 4 - 123 m2 ** KOumassi sicogi</li>
            <li class="list-group-item">Bien 5 - 123 m2 ** KOumassi sicogi</li>
            </ul>`,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ajouter un bien',
            cancelButtonText: 'fermer!',
        }).then(async (result) => {
            if (result.isConfirmed) {

                this.create();
            }
        })
    }
    create(){
        Swal.fire({
            title: 'Création de bien',
            html: `<input type="text"  class="swal2-input" placeholder="Nom du bien">
            <input type="text"  class="swal2-input" placeholder="superficie">`,
            confirmButtonText: 'Ajouter bien',
            focusConfirm: false,
            }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Crée avec succès!',
                    'success'
                )
            }
        })
    }


}
