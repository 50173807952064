/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
export default class PaymentExtend {
    public SUCCESS = 'Succès';
    public ECHEC = 'Echec';

    constructor(object?: any) {
//
    }
}
