<template>
  <div class="bar-menu-item"
       @mousedown="(e) => e.preventDefault()"
       @click="click"
       :class="{ disabled: item.disabled, active: item.active }"
       :title="item.title"
       :style="{ height: item.height+'px' }">

    <i v-if="item.icon" :class="item.icon"></i>
    <!--    <span v-if="item.emoji" class="emoji">{{ get_emoji(item.emoji) }}</span>-->
    <span v-if="item.text" class="label">{{ item.text }}</span>
    <span v-if="item.html" class="label" v-html="item.html"></span>
    <span v-if="item.hotkey" class="hotkey">{{ hotkey }}</span>

    <span v-if="item.menu && item.custom_chevron" class="chevron" v-html="item.custom_chevron"></span>
    <i v-else-if="item.menu" class="fad fa-angle-down ms-1"></i>

    <component ref="menu" class="menu" v-if="item.menu"
               :is="get_component(item.menu)"
               :menu="item.menu"
               :class="item.menu_class"
               :id="item.menu_id"
               :width="item.menu_width"
               :height="item.menu_height" />

  </div>
</template>

<script>
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  /* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import emoji from 'node-emoji'
import hotkey_manager from './imports/bar-hotkey-manager.js'

export default {
  mixins: [ hotkey_manager ],

  components: {
    BarMenu: () => import('./BarMenu.vue') // recursive component
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  methods: {
    click (e) {
      if(this.item.click && !this.item.disabled) this.item.click(e);
      else if(!this.$refs.menu || !e.composedPath || !e.composedPath().includes(this.$refs.menu.$el)) {
        e.stopPropagation(); // prevent menu close for touch devices
      }
    },
    get_emoji: emoji_name => emoji.get(emoji_name),
    get_component (is) {
      if(is && !Array.isArray(is) && typeof is == "object") return is;
      else return "bar-menu";
    }
  }
}
</script>
