import { render, staticRenderFns } from "./userShow.vue?vue&type=template&id=6bad62a3&scoped=true&"
import script from "./userShow.vue?vue&type=script&lang=ts&"
export * from "./userShow.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bad62a3",
  null
  
)

export default component.exports