/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */

export default class InvoiceLineExtend {
    TYPES = {
        'FEES': 'Frais',
        'PENALITY': 'Pénalité',
        'SCHEDULE': 'Echéance',
    };

    public payedAmount = 0;
    public remainingAmount = 0;


    constructor(object?: any) {
        if (object) {
            this.payedAmount = object.payedAmount;
            this.remainingAmount = object.remainingAmount;
        }
    }



}
