

































































































































































































































































































































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Subscription from "@/models/Subscription";
import Loading from "@/components/Loading.vue";
import FileUpload from "@/components/FileUpload.vue";
import Swal from "sweetalert2";
import {auth} from '@/services/Auth';
import Invoice from '@/models/Invoice';
import InvoiceLine from '@/models/InvoiceLine';

@Component({
    components: {
        FileUpload,
        Loading,
        Base
    }
})
export default class subscriptionShow extends Vue {
    public sub: Subscription | null = null;
    public isLoading = false;
    public penality: any = 0;
    public TYPES = {
        'FEES': 'Frais',
        'PENALITY': 'Pénalité',
        'SCHEDULE': 'Echéance',
    };

    async mounted(): Promise<void> {
        await this.loadSub(this.$route.params.uuid);
    }

    async downloadFacture(uuid: string): Promise<void> {
        await api.download('shared/download/invoice/' + uuid, 'Facture_' + uuid + '.pdf');
    }

    async downloadContract(): Promise<void> {
        if (this.sub && this.sub.property) {
            let name = 'Contrat_' + this.sub.property.promotion.title;
            name += '_bloc_' + this.sub.property.block;
            name += '_lot_' + this.sub.property.lot;
            name += '.pdf';
            await api.download('api/subscription/download/contract/' + this.sub.uuid, name);
        }

    }

    async removeFile(uuid: string): Promise<void> {
        const result = await Swal.fire({
            title: 'Suppression',
            text: "Voulez-vous vraiment supprimer ce fichier ? ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, Supprimer!',
            cancelButtonText: 'Non, annuler!',
        });
        if (result.isConfirmed) {
            const res = await api.get('api/uploader/delete/file/' + uuid);
            if (res.status === 'success' && this.sub) {
                this.loadSub(this.sub.uuid);
                Swal.fire({
                        title: 'Succès',
                        text: 'Le fichier a été supprimé avec succès.',
                        icon: 'success',
                        timer: 2000
                    }
                );
            }
        }
    }

    async validateDocument(uuid: string): Promise<void> {
        if (auth.isAdmin()) {
            const result = await Swal.fire({
                title: 'Validation',
                text: "Voulez-vous vraiment valider ce fichier ? ",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, Valider!',
                cancelButtonText: 'Non, annuler!',
            });
            if (result.isConfirmed) {
                const res = await api.get('api/validate/uploaded/file/' + uuid);
                if (res.status === 'success' && this.sub) {
                    this.loadSub(this.sub.uuid);
                    Swal.fire({
                            title: 'Succès',
                            text: 'Le fichier a été validé avec succès.',
                            icon: 'success',
                            timer: 2000
                        }
                    );
                }
            }
        }
    }

    async downloadEcheancier(): Promise<void> {
        if (this.sub && this.sub.property) {
            let name = '';
            if (this.sub.property.promotion) {
                name += this.sub.property.promotion.title;
                if (this.sub.property.promotion.company) {
                    name += this.sub.property.promotion.company.longName;
                }
            }
            name += this.sub.property.uuid;
            this.$store.commit('load');
            await api.download('shared/download/scheduler/' + this.sub.uuid, 'Echeancier_' + name + '.pdf');
            this.$store.commit('stopLoad');
        }
    }

    async downloadInvoice(invoice: Invoice) {
        this.$store.commit('load');
        await api.download('shared/download/invoice/' + invoice.uuid, 'Facture_N_' + invoice.numero + '.pdf');
        this.$store.commit('stopLoad');
    }

    async loadSub(uuid: string): Promise<void> {
        this.isLoading = true;
        const res = await api.get('api/subscription/' + uuid + '/get');
        this.sub = new Subscription(res.subscription);
        this.sub.invoices.forEach((invoice: Invoice) => {
            invoice.invoiceLines.forEach((line: InvoiceLine) => {

                if (line.type === line.TYPES.PENALITY) {
                    this.penality++;
                }

            })

        });

        this.isLoading = false;
    }

    isSubActive(): boolean {
        if (this.sub) {
            return this.sub.status === this.sub.EN_COURS || this.sub.status === this.sub.TERMINE
        }
        return false;
    }
}
