/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import DocumentRequired from "./DocumentRequired";
import Property from "./Property";
import User from "./User";

export default class PropertyType {

    public id: number;
    public title? = '';
    public documents: Array<DocumentRequired> = [];
    public properties: Array<Property> = [];
    public uuid = '';
    public createdAt?: Date;
    public updatedAt?: Date;
    public createBy?: User;
    public updateBy?: User;
    public removeBy?: User;
    public createdFromIp? = '';
    public updatedFromIp? = '';
    public deletedAt?: Date;

    constructor(object?: any) {
        if (object) {
            this.id = object.id;
            this.title = object.title;
            if (object.documents) {
                object.documents.forEach((occ: any) => {
                    this.documents.push(new DocumentRequired(occ));
                });
            }
            if (object.properties) {
                object.properties.forEach((occ: any) => {
                    this.properties.push(new Property(occ));
                });
            }
            this.uuid = object.uuid;
            if (object.createdAt) {
                this.createdAt = new Date(object.createdAt);
            }
            if (object.updatedAt) {
                this.updatedAt = new Date(object.updatedAt);
            }
            this.createBy = (object.createBy instanceof User) ? object.createBy : new User(object.createBy);
            this.updateBy = (object.updateBy instanceof User) ? object.updateBy : new User(object.updateBy);
            this.removeBy = (object.removeBy instanceof User) ? object.removeBy : new User(object.removeBy);
            this.createdFromIp = object.createdFromIp;
            this.updatedFromIp = object.updatedFromIp;
            if (object.deletedAt) {
                this.deletedAt = new Date(object.deletedAt);
            }
        }
    }

}

