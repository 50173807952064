
















































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import PropertyType from "@/models/PropertyType";
import {api} from "@/services/Api";
import Swal from 'sweetalert2';
import Loading from "@/components/Loading.vue";

@Component({
    components: {
        Loading,
        Base
    }
})
export default class TypeList extends Vue {
    page = 1;
    public types: Array<PropertyType> = [];
    public loading = false;

    async load() {
        this.loading = true;
        const res = await api.get('api/property/type/list/' + this.page);
        res.types.forEach((item: any) => {
            this.types.push(new PropertyType(item));
        });
        this.loading = false;
    }

    mounted(): void {
        this.load();
    }

    async deleteType(uuid: any) {
        Swal.fire({
            title: 'Type de bien',
            text: "Voulez-vous vraiment supprimer ce type de bien?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Non, annuler!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await api.delete('api/property/type/' + uuid + '/delete');
                if (res.data.status === 'success') {
                    this.load();
                    Swal.fire(
                        'Supprimé!',
                        'Promotion supprimée avec succès.',
                        'success'
                    )

                }
            }
        })
    }
}
