/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import ContactRole from "./ContactRole";
import Person from "./Person";

export default class Contact {

public id: number;
public createdAt: Date;
public title? = '';
public address1? = '';
public address2? = '';
public phone = '';
public email? = '';
public note? = '';
public role?: ContactRole;
public person?: Person;

  constructor (object?: any) {
      if(object){
       this.id= object.id;
       if(object.createdAt){
           this.createdAt= new Date(object.createdAt);
       }
       this.title= object.title;
       this.address1= object.address1;
       this.address2= object.address2;
       this.phone= object.phone;
       this.email= object.email;
       this.note= object.note;
       this.role= new ContactRole( object.role);
       this.person= new Person( object.person);
      }
  }

}
