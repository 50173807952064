




























































































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Subscription from "@/models/Subscription";
import {api} from "@/services/Api";
import Swal from 'sweetalert2';
import Loading from "@/components/Loading.vue";
import Promotion from "@/models/Promotion";
import FilterButton from "@/components/FilterButton.vue";
import Pagination from "@/components/Pagination.vue";

@Component({
    components: {
        Pagination,
        FilterButton,
        Loading,
        Base
    }
})
export default class subscriptionList extends Vue {
    public subscriptions: Array<Subscription> = [];
    public promotions: Promotion[] = [];
    public matchCount = 0;
    public total = 0;

    public loading = false;
    public filter = {
        searched: '',
        promotion: null,
        available: true,
        unavailable: true,
        ilot: null,
        lot: null,
        budgetMin: 0,
        budgetMax: 0,
        page: 1,
        perPage: 5,
    };

    setPage(num: number) {
        this.filter.page = num;
        this.load();
    }

    get from() {
        return ((this.filter.page - 1) * this.filter.perPage) + 1;
    }

    get maxPage() {
        return this.total > 0 ? Math.ceil(this.total / this.filter.perPage) : 1;
    }

    get to() {
        return this.from + this.subscriptions.length - 1;
    }


    initFilter() {
        this.filter = {
            searched: '',
            promotion: null,
            available: true,
            unavailable: true,
            ilot: null,
            lot: null,
            budgetMin: 0,
            budgetMax: 0,
            page: 1,
            perPage: 5,
        };
    }

    async load(): Promise<void> {
        if (this.$refs.hasOwnProperty('filter')) {
            (this.$refs.filter as any).hide(true);
        }
        if (this.filter.page > this.maxPage) {
            return;
        }
        this.loading = true;
        this.subscriptions = [];
        const res = await api.post('api/subscription/list', {filter: this.$store.state.filter});
        this.matchCount = res.data.matchCount;
        this.total = res.data.count;
        res.data.subscriptions.forEach((item: any) => {
            this.subscriptions.push(new Subscription(item));
        });
        this.loading = false;
    }

    mounted(): void {
        this.load();
        this.loadPromotions();
    }

    async loadPromotions() {
        const res = await api.get('api/promotion/admin/list');
        res.promotions.forEach((item: Promotion) => {
            this.promotions.push(new Promotion(item));
        });
    }

    async cancelSubscription(uuid: any) {
        Swal.fire({
            title: 'Annulation',
            text: "Voulez-vous vraiment annuler cette souscription ?" +
                "Toutes les données liées à celle ci seront effacées",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, Annuler!',
            cancelButtonText: 'Non, conserver!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await api.get('api/subscription/' + uuid + '/cancel');
                if (res.status === 'success') {
                    Swal.fire({
                            title: 'Succès',
                            text: 'Souscription annulée avec succès.',
                            icon: 'success',
                            timer: 2000
                        }
                    );
                    this.load();
                }
            }
        })
    }
}
