


















































































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Prop, Vue} from 'vue-property-decorator';
import Person from "@/models/Person";
import Subscription from "@/models/Subscription";
import {api} from "@/services/Api";
import Contact from "@/models/Contact";
import Loading from "@/components/Loading.vue";

@Component({
    components: {Loading}
})
export default class SelectPerson extends Vue {
    @Prop() subscription: Subscription;
    public persons: Person[] = [];
    public person = new Person();
    public isCreatingUser = false;
    public isSearching = false;
    public searched = '';
    public loadingPerson = false;

    public next() {
        this.$emit('next');
    }
    public previous() {
        this.$emit('previous');
    }

    public removePerson(): void {
        this.$set(this.subscription, 'person', undefined);
        this.$forceUpdate();
        this.$emit('person', undefined);
        // this.subscription.person = undefined;
    }

    selectPerson(person: Person): void {
        this.subscription.person = person;
        this.isSearching = false;
        this.$emit('person', person);
    }

    isPersonValid(): boolean {
        return this.subscription.person instanceof Person && this.subscription.person.uuid.trim().length > 0
    }

    async searchUser(): Promise<void> {
        this.persons = [];
        this.isSearching = true;
        this.loadingPerson = true;
        const res = await api.post('api/person/search', {search: this.searched});
        res.data.persons.forEach((item: Person) => {
            this.persons.push(new Person(item));
        });
        this.loadingPerson = false;
    }

    createUser(): void {
        this.isCreatingUser = true;
        const contact = new Contact();
        this.subscription.person = new Person();
        this.subscription.person.contacts.push(contact);
    }

    async saveUser(): Promise<void> {
        const res = await api.post('api/person/new', {person: this.person});
        if (res.data.status === 'success') {
            this.$bvModal.hide('createUser');
            const pers = new Person(res.data.person);
            this.subscription.person = pers;
            this.selectPerson(pers);
            this.$forceUpdate();
        }
    }
}
