/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import Person from "./Person";
import Invoice from "./Invoice";
import Property from "./Property";
import User from "./User";
import SubscriptionDocument from "@/models/SubscriptionDocument";
import SubscriptionExtend from "@/models/extends/SubscriptionExtend";

export default class Subscription extends SubscriptionExtend{

    public id: number;
    public contractDate?: Date;
    public startDate: Date;
    public price = 0;
    public tva: number;
    public ht: number;
    public htWithoutFees: number;
    public status = '';
    public person?: Person;
    public duration: number;
    public recurrent: number;
    public recurrentTtc: number;
    public invoices: Array<Invoice> = [];

    public documents: Array<SubscriptionDocument> = [];
    public property?: Property;
    public administrativeFees?: number;
    public uuid = '';
    public createdAt?: Date;
    public updatedAt?: Date;
    public createBy?: User;
    public updateBy?: User;
    public removeBy?: User;
    public createdFromIp? = '';
    public updatedFromIp? = '';
    public deletedAt?: Date;

    constructor(object?: any) {
        super(object);
        if (object) {
            this.id = object.id;
            if (object.contractDate) {
                this.contractDate = new Date(object.contractDate);
            }
            if (object.startDate) {
                this.startDate = new Date(object.startDate);
            }
            this.price = object.price;
            this.tva = object.tva;
            this.ht = object.ht;
            this.htWithoutFees = object.htWithoutFees;
            this.status = object.status;
            this.person = new Person(object.person);
            this.duration = object.duration;
            this.recurrent = object.recurrent;
            this.recurrentTtc = object.recurrentTtc;
            if (object.invoices) {
                object.invoices.forEach((occ: any) => {
                    this.invoices.push(new Invoice(occ));
                });
            }

            if (object.documents) {
                object.documents.forEach((occ: any) => {
                    this.documents.push(new SubscriptionDocument(occ));
                });
            }
            this.property = new Property(object.property);
            this.administrativeFees = object.administrativeFees;
            this.uuid = object.uuid;
            if (object.createdAt) {
                this.createdAt = new Date(object.createdAt);
            }
            if (object.updatedAt) {
                this.updatedAt = new Date(object.updatedAt);
            }
            this.createBy = new User(object.createBy);
            this.updateBy = new User(object.updateBy);
            this.removeBy = new User(object.removeBy);
            this.createdFromIp = object.createdFromIp;
            this.updatedFromIp = object.updatedFromIp;
            if (object.deletedAt) {
                this.deletedAt = new Date(object.deletedAt);
            }
        }
    }

}
