























































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Prop, Vue} from 'vue-property-decorator';
import Person from "@/models/Person";
import {api} from "@/services/Api";
import Loading from "@/components/Loading.vue";
import Pagination from "@/components/Pagination.vue";

@Component({
    components: {Pagination, Loading}
})
export default class clientTab extends Vue {
    public page = 1;
    public persons: Array<Person> = [];
    public loading = false;
    public searched = '';
    public count = 0;
    public matchCount = 0;
    public total = 0;
    @Prop() showHeader?: boolean;
    @Prop() showFooter?: boolean;

    setPage(num: number) {
        this.$store.state.filter.page = num;
        this.load();
    }

    get from() {
        return ((this.$store.state.page - 1) * this.$store.state.perPage) + 1;
    }

    get maxPage() {
        return this.total > 0 ? Math.ceil(this.total / this.$store.state.perPage) : 1;
    }

    get to() {
        return this.from + this.persons.length - 1;
    }


    async load(): Promise<void> {
        this.loading = true;
        this.persons = [];
        const res = await api.post('api/person/list', {filter: this.$store.state.filter});
        res.data.persons.forEach((item: Person) => {
            this.persons.push(new Person(item));
        });
        this.matchCount = res.data.matchCount;
        this.total = res.data.count;
        this.loading = false;
    }

    async mounted(): Promise<void> {
        await this.load();
    }
}
