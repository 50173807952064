















































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Property from "@/models/Property";
import {api} from "@/services/Api";
import Swal from 'sweetalert2';
import {VuejsDatatableFactory} from 'vuejs-datatable';
import Loading from "@/components/Loading.vue";
import Promotion from "@/models/Promotion";
import FilterButton from "@/components/FilterButton.vue";
import BienTab from "@/views/admin/biens/bienTab.vue";

@Component({
    components: {
        BienTab,
        FilterButton,
        Loading,
        Base
    }
})
export default class bienList extends Vue {
    private key = 1;

    load(){
        this.key++;
    }

}
