






















































import {Component, Vue} from 'vue-property-decorator';
import {auth} from "./services/Auth";
import Popup from "@/components/Popup.vue";
import Loading from "@/components/Loading.vue";

@Component({
    components: {Loading, Popup}
})
export default class App extends Vue {
    public fixedToolbar = true;
    public toolbarEnabled = true;

    mounted(): void {
        if (!this.$route.name) {
            if (auth.isAdmin()) {
                this.$router.push({name: 'dashboard'});
            } else {
                this.$router.push({name: 'myDashboard'});
            }
        }
        this.fixedToolbar = true;
        this.toolbarEnabled = true;
        this.$on('hideToolbar', () => {
            this.fixedToolbar = false;
            this.toolbarEnabled = false;
        });
    }
}
