

















































































































































































































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Invoice from "@/models/Invoice";
import {api} from "@/services/Api";
import Person from "@/models/Person";
import Property from "@/models/Property";
import Payment from "@/models/Payment";
import Stamp from "@/components/Stamp.vue";
import Loading from "@/components/Loading.vue";

@Component({
    components: {
        Loading,
        Stamp,
        Base
    }
})
export default class invoiceShow extends Vue {
    public invoice?: Invoice | null = null;
    public person?: Person | null = null;
    public loading = false;
    public property?: Property | null = null;
    public payment: Payment = new Payment();

    async mounted() {
        await this.loadInvoice(this.$route.params.uuid);
        this.payment.type = 'espèce';
    }

    async pay() {
        if (this.isPaymentValid() && this.invoice) {
            console.log(this.payment);
            const res = await api.post('api/payment/new', {payment: this.payment, 'invoice': this.invoice.uuid});
            console.log(res.data);
            if (res.data.status === 'success') {
                const payment = new Payment(res.data.payment);
                if (payment.invoice && payment.invoice.subscription) {
                    this.$router.push({
                        name: 'souscription.show',
                        params: {uuid: payment.invoice.subscription.uuid}
                    });
                }
            }
            // this.$bvModal.hide('payment');
        }
    }

    isPaymentValid(): boolean {
        if (this.payment) {
            if (this.payment.type === 'chèque') {
                return this.payment.amount > 0 && this.payment.source.trim().length > 0 && this.payment.numero.trim().length > 0;
            }
            if (this.payment.type === 'espèce') {
                return this.payment.amount > 0;
            }
        }
        return false;
    }

    async loadInvoice(uuid: string) {
        const res = await api.get('api/invoice/' + uuid + '/get');
        this.invoice = new Invoice(res.invoice);
        this.person = new Person(res.person);
        this.property = new Property(res.property);
        this.payment.amount = this.invoice.ttc;
    }
}
