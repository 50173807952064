







































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Payment from "@/models/Payment";
import Loading from "@/components/Loading.vue";
import Promotion from "@/models/Promotion";
import PaiementTab from "@/views/admin/paiement/paiementTab.vue";
import FilterButton from "@/components/FilterButton.vue";

@Component({
    components: {
        FilterButton,
        PaiementTab,
        Loading,
        Base
    }
})
export default class paiementList extends Vue {

    private key = 1;

    load() {
        this.key++;
    }
}
