



















































































































































































































































































































































































































































































































































































    /* eslint-disable  @typescript-eslint/no-explicit-any */
    /* eslint-disable  @typescript-eslint/no-unused-vars */
    /* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
    import {Component, Vue} from 'vue-property-decorator';
    import Base from "@/layouts/Base.vue";
    import Property from "@/models/Property";
    import {api} from "@/services/Api";
    import Subscription from "@/models/Subscription";
    import Person from "@/models/Person";
    import Contact from "@/models/Contact";
    import {DateHelperService} from "@/utils/date-helper.service";
    import Invoice from "@/models/Invoice";
    import Payment from "@/models/Payment";
    import Popup from "@/models/Popup";
    import Loading from "@/components/Loading.vue";
import Swal from 'sweetalert2';

    @Component({
        components: {
            Loading,
            Base
        }
    })
    export default class CustomerSubscriptionForm extends Vue {

        public subscription = new Subscription();
        public person = new Person();
        public isCreatingUser = false;
        public isSearching = false;
        public searched = '';
        public selectedProperty?: Property;
        public persons: Array<Person> = [];
        public payment: Payment = new Payment();
        public ht = 0;
        public tva = 0;
        public ttc = 0;
        public edit = false;
        public startDate = '';
        public loadingProperty = false;
        public loadingPerson = false;
        public loadingScheduler = false;
        public step = {
            bien: 1,
            client: 2,
            echeancier: 3,
            recap: 4,
        };

        public properties: Array<Property> = [];
        public currentStep = 1;

        async loadProperties(): Promise<void> {
            this.loadingProperty = true;
            const res = await api.get('api/property/available');
            res.properties.forEach((item: Property) => {
                this.properties.push(new Property(item));
            });
            this.loadingProperty = false;
        }


        selectProperty(property: Property): void {
            if (!property.promotion || !property.price) {
                return
            }
            this.selectedProperty = property;
            this.subscription.duration = property.promotion.duration;
            this.subscription.price = property.price;
            if (property.promotion.duration > 0) {
                this.subscription.recurrent = Math.floor(property.price / property.promotion.duration);
            }
            this.setStartDate();

            // this.save();
        }

        async schedule(): Promise<void> {
            this.setStartDate();
            if (this.subscription.property && this.subscription.property.uuid) {
                const payload = {
                    date: this.subscription.startDate,
                    duration: this.subscription.duration,
                    recurrent: this.subscription.recurrent,
                    property: this.subscription.property.uuid,
                };
                const res = await api.post('api/property/get/scheduler', payload);
                if (res.data.invoices) {
                    this.subscription.invoices = [];
                    res.data.invoices.forEach((invoice: Invoice) => {
                        this.subscription.invoices.push(new Invoice(invoice));
                    });
                    if (this.subscription.invoices.length > 0) {
                        this.payment.amount = this.subscription.invoices[0].ttc;
                    }
                }
            } else {
                new Popup('Formulaire incomplet', 'Il semblerait qu\'aucun bien n\'ai été sélectionné', 'light-warning');
            }
        }

        calculate(): void {
            this.subscription.person = JSON.parse(localStorage.person);
            if (this.subscription && this.subscription.property && this.subscription.property.promotion &&
                this.subscription.property.price) {
                this.subscription.recurrent = Math.floor(this.subscription.property.price / this.subscription.duration);
                if (this.subscription.invoices.length > 0) {
                    this.payment.amount = this.subscription.invoices[0].ttc;
                }
                this.payment.type = 'chèque';
            }
        }


        readableDate(date: Date | string | undefined): string {
            return DateHelperService.readable(date);
        }

        isEcheancierValid(): boolean {
            return this.subscription.recurrent > 0 && this.subscription.duration > 0
                && this.subscription.startDate !== undefined;
        }

        isPaymentValid(): boolean {
            if (this.payment) {
                if (this.payment.type === 'chèque') {
                    if (this.payment.source && this.payment.numero) {
                        return this.payment.amount > 0 && this.payment.source.trim().length > 0
                            && this.payment.numero.trim().length > 0;
                    }
                } else if (this.payment.type === 'espèce') {
                    return this.payment.amount > 0;
                }
            }
            return false;
        }

        async save(): Promise<void> {
            if (this.isPaymentValid()) {
                const res = await api.post('api/subscription/new', {
                    subscription: this.subscription,
                    payment: this.payment
                });
                if (res.data.status === 'success') {
                    this.$router.push({name: 'mySubscription.show', params: {uuid: res.data.subscription}});
                }else{
                    Swal.fire(
                            'Erreur!',
                            'La souscription a échouée.',
                            'error'
                        );

                }
            }
        }

        goToScheduler(): void {
            this.currentStep = 3;
            this.calculate();
            this.schedule();
        }

        setStartDate(): void {
            if (this.subscription.property && this.subscription.property.promotion && !this.subscription.startDate && !this.startDate) {
                const d = this.subscription.property.promotion.billingDay;
                let now = new Date();
                now.setDate(d);
                this.subscription.startDate = now;
                const m = (this.subscription.startDate.getMonth() + 1) <= 9 ? '0' : '';
                this.startDate = this.subscription.startDate.getFullYear() + '-' + m + (this.subscription.startDate.getMonth() + 1);
            } else if (this.subscription.property && this.subscription.property.promotion) {
                const d = this.subscription.property.promotion.billingDay;
                const m = d <= 9 ? '0' + d : d;
                let day = new Date(this.startDate + '-' + m);
                this.subscription.startDate = day;
            }
        }

        async mounted(): Promise<void> {

            if (this.$route.params.hasOwnProperty('uuid')) {
                if (this.$route.params.uuid) {
                    this.edit = true;
                    await this.loadSub(this.$route.params.uuid);
                }
            }
            if (this.$route.params.propertyUuid) {
                await this.getProperty(this.$route.params.propertyUuid);
            } else {
                await this.loadProperties();
            }

            const contact = new Contact();
            this.person.contacts.push(contact);
            if (!this.subscription.property) {
                this.currentStep = 1;
            } else if (!this.subscription.person) {
                this.currentStep = 2;
            } else if (!this.isEcheancierValid()) {
                this.currentStep = 3;
            } else if (this.isEcheancierValid()) {
                this.currentStep = 4;
            }
            this.calculate();
        }

        async loadSub(uuid: string): Promise<void> {
            const res = await api.get('api/subscription/' + uuid + '/get');
            this.subscription = new Subscription(res.subscription);
            this.payment.amount = this.subscription.invoices[0].ttc;
            const m = (this.subscription.startDate.getMonth() + 1) <= 9 ? '0' : '';
            this.startDate = this.subscription.startDate.getFullYear() + '-' + m + (this.subscription.startDate.getMonth() + 1);
        }

        async getProperty(uuid: string): Promise<void> {
            const res = await api.get('api/property/' + uuid + '/get');
            const property = new Property(res.property);
            this.subscription.property = property;
            this.properties = [property];
        }

        round(amount: number): number {
            return Math.round(amount);
        }

        round2(amount: number): number {
            return Math.round(amount * 100) / 100;
        }
    }
