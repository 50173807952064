/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */

export default class PersonType {

public id: number;
public title = '';

  constructor (object?: any) {
      if(object){
       this.id= object.id;
       this.title= object.title;
      }
  }

}
