















































































































































































































































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Subscription from "@/models/Subscription";
import Loading from "@/components/Loading.vue";

import {api} from "@/services/Api";
import Person from "@/models/Person";
import Invoice from "@/models/Invoice";
import {auth} from "@/services/Auth";
import CONSTANTS from "@/utils/constant";

@Component({
    components: {
        Loading,
        Base
    }
})
export default class CustomerSubscriptionList extends Vue {
    public subscriptions: Array<Subscription> = [];
    public loading = false;
    public matchCount = 0;
    public person: Person | null = null;
    public total = 0;
    public page = 1;
    public perPage = 10;
    public nextInvoice: Invoice | null = null;
    public sum: any = 0;
    public paye: any = 0;
    public CONSTANTS = CONSTANTS;

    setPage(num: number): void {
        this.page = num;
        this.load();
    }

    get from(): number {
        return ((this.page - 1) * this.perPage) + 1;
    }

    get maxPage(): number {
        return this.total > 0 ? Math.ceil(this.total / this.perPage) : 1;
    }

    get to(): number {
        return this.from + this.subscriptions.length - 1;
    }


    async load(): Promise<void> {
        this.subscriptions = [];
        this.loading = true;
        const person = await api.get('api/person/me');
        if (person.person) {
            this.person = person.person;
        }
        const res = await api.get('api/subscription/list');
        res.subscriptions.forEach((item: Subscription) => {
            const sub = new Subscription(item);
            if (sub.status === CONSTANTS.subscriptionStatus.EN_COURS || sub.status === CONSTANTS.subscriptionStatus.TERMINE) {
                this.sum += (item.price);
                this.paye += (item.paiement);
            }
            this.subscriptions.push(sub);
        });
        this.matchCount = res.matchCount;
        this.total = res.count;

        const infos = await api.get('api/invoice/status/infos');
        if (infos.next) {
            this.nextInvoice = new Invoice(infos.next);
        }

        this.loading = false;
        this.$forceUpdate();
    }

    mounted(): void {
        if (auth.isAdmin()) {
            this.$router.push({name: 'dashboard'});
            return;
        }
        this.load();
    }
}
