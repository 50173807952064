































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































    import {Component, Vue} from 'vue-property-decorator';
    import {api} from "@/services/Api";
    import User from "@/models/User";
import Subscription from '@/models/Subscription';

    @Component({})
    export default class RightMenu extends Vue {
        public notificationTabIndex = 1;
        public user: User = new User();
        public person = false;
        public subscriptions: Array<Subscription> = [];

        logout(): void {
            api.logout();
        }

        mounted(): void {
            this.user = this.$store.getters.getUser();
            if (this.user && localStorage.person) {
                this.person = true;
                this.user = JSON.parse(localStorage.person);
            }
            // this.load()

            // console.log(KTMenu);
            // KTMenu.init();
        }
        async load(): Promise<void> {
            const res = await api.get('api/subscription/list');
            res.subscriptions.forEach((item: Subscription) => {
                this.subscriptions.push(new Subscription(item));
            })
        }
    }
