/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import File from "./../File";

export default class SubscriptionDocumentExtend {
    STATUS = {
        'EN_ATTENTE': 'En attente',
        'VALIDE': 'Validé',
        'INVALIDE': 'Invalide'
    };
    public file?: File;
    public available = true;

    constructor(object?: any) {
        if (object) {
            if (object.file) {
                this.file = new File(object.file);
            }
        }
    }
}
