




















































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Prop, Vue} from 'vue-property-decorator';
import Payment from "@/models/Payment";
import {api} from "@/services/Api";
import Loading from "@/components/Loading.vue";
import Pagination from "@/components/Pagination.vue";

@Component({
    components: {Pagination, Loading}
})
export default class paiementTab extends Vue {
    @Prop() showHeader?: boolean;
    @Prop() showFooter?: boolean;
    @Prop() personUuid?: string;
    public payments: Payment[] = [];
    public loading = false;
    public searched = '';
    public count = 0;
    public matchCount = 0;
    public total = 0;

    async load(): Promise<void> {
        this.loading = true;
        this.payments = [];

        if (this.$refs.hasOwnProperty('filter')) {
            (this.$refs.filter as any).hide(true);
        }
        let url = 'api/payment/list';
        if (this.personUuid) {
            url += '/' + this.personUuid;
        }
        const res = await api.post(url, {filter: this.$store.state.filter});
        this.loading = false;
        if (res.data && res.data.payments) {
            res.data.payments.forEach((item: any) => {
                this.payments.push(new Payment(item));
            });
            this.matchCount = res.data.matchCount;
            this.total = res.data.count;
        }

    }

    async mounted(): Promise<void> {
        this.load();
        // this.loadPromotions();
    }
}
