/* eslint-disable  @typescript-eslint/no-explicit-any */
import store from "@/store";

export default class Popup {
    public id: string;
    public type: string;
    public title: string;
    public message: string;
    public icon: string;
    public link: string;
    public buttons: [];
    public timeout?: any;

    constructor(title = '', message: string, type = 'secondary', icon = 'fad fa-exclamation',
                link ?: string, buttons?: []) {
        this.id = this.generateId();
        this.type = type;
        this.title = title;
        this.message = message;
        if (icon)
            this.icon = icon;
        if (link)
            this.link = link;
        if (buttons)
            this.buttons = buttons;

        store.state.popups.push(this);
        this.timeout = setTimeout(() => {
            store.commit('removePopup', this.id);
            clearTimeout(this.timeout);
        }, 5000);
        return this;
    }

    generateId(): string {
        const number = Math.random();
        number.toString(36);
        return number.toString(36).substr(2, 9);
    }
}
