









































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Prop, Vue} from 'vue-property-decorator';
import Subscription from "@/models/Subscription";
import Payment from "@/models/Payment";
import {api} from "@/services/Api";
import Invoice from "@/models/Invoice";
import Popup from "@/models/Popup";
import {auth} from "@/services/Auth";

@Component({})
export default class Scheduler extends Vue {
    @Prop() subscription: Subscription;
    @Prop() payment: Payment;
    public startDate: any = '';

    mounted() {
        this.calculate();
        this.schedule();
    }

    public next() {
        this.calculate();
        this.$emit('next');
    }

    public previous() {
        this.$emit('previous');
    }

    calculate(): void {
        if (this.subscription && this.subscription.property && this.subscription.property.promotion &&
            this.subscription.property.price) {
            /* eslint-disable-next-line */
            this.subscription.recurrent = (this as any).round2(this.subscription.property.price / this.subscription.duration);
            if (this.subscription.invoices.length > 0) {
                this.payment.amount = this.subscription.invoices[0].ttc;
            }
            // this.payment.type = 'chèque';
        }
    }

    async schedule(): Promise<void> {
        this.setStartDate();
        if (this.subscription.property && this.subscription.property.uuid) {
            const payload = {
                date: this.subscription.startDate,
                duration: this.subscription.duration,
                recurrent: this.subscription.recurrent,
                property: this.subscription.property.uuid,
            };
            this.$store.commit('load');
            const res = await api.post('api/property/get/scheduler', payload);
            if (res.data.invoices) {
                this.subscription.invoices = [];
                res.data.invoices.forEach((invoice: Invoice) => {
                    this.subscription.invoices.push(new Invoice(invoice));
                });
                if (this.subscription.invoices.length > 0) {
                    this.payment.amount = this.subscription.invoices[0].ttc;
                }
            }
            this.$store.commit('stopLoad');
        } else {
            new Popup('Formulaire incomplet', 'Il semblerait qu\'aucun bien n\'ai été sélectionné', 'light-warning');
        }
    }

    isEcheancierValid(): boolean {
        return this.subscription.recurrent > 0 && this.subscription.duration > 0
            && this.subscription.startDate !== undefined;
    }

    setStartDate(): void {
        if (this.subscription.property && this.subscription.property.promotion && !this.subscription.startDate && !this.startDate) {
            const d = this.subscription.property.promotion.billingDay;
            let now = new Date();
            this.subscription.startDate = now;
            const m = (this.subscription.startDate.getMonth() + 1) <= 9 ? '0' : '';
            this.startDate = this.subscription.startDate.getFullYear() + '-' + m + (this.subscription.startDate.getMonth() + 1);
        } else if (this.subscription.property && this.subscription.property.promotion) {
            if (this.startDate && auth.isAdmin()) {
                const d = this.subscription.property.promotion.billingDay;
                const m = d <= 9 ? '0' + d : d;
                let day = new Date(this.startDate + '-' + m);
                this.subscription.startDate = day;
            } else if (this.subscription.startDate) {
                const m = (this.subscription.startDate.getMonth() + 1) <= 9 ? '0' : '';
                this.startDate = this.subscription.startDate.getFullYear() + '-' + m + (this.subscription.startDate.getMonth() + 1);
            }
        }
    }
}
