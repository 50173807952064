/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import Invoice from "./Invoice";
import Receipt from "./Receipt";
import User from "./User";
import PaymentExtend from "@/models/extends/PaymentExtend";

export default class Payment extends PaymentExtend {

    public id: number;
    public createdAt: Date;
    public date: Date;
    public amount: number;
    public invoice?: Invoice;
    public receipt?: Receipt;
    public status = '';
    public type = '';
    public numero = '';
    public source = '';
    public uuid = '';
    public updatedAt?: Date;
    public createBy?: User;
    public updateBy?: User;
    public removeBy?: User;
    public createdFromIp? = '';
    public updatedFromIp? = '';
    public deletedAt?: Date;

    constructor(object?: any) {
        super(object);
        if (object) {
            this.id = object.id;
            if (object.createdAt) {
                this.createdAt = new Date(object.createdAt);
            }
            if (object.date) {
                this.date = new Date(object.date);
            }
            this.amount = object.amount;
            this.invoice = new Invoice(object.invoice);
            this.receipt = new Receipt(object.receipt);
            this.status = object.status;
            this.type = object.type;
            this.numero = object.numero;
            this.source = object.source;
            this.uuid = object.uuid;
            if (object.updatedAt) {
                this.updatedAt = new Date(object.updatedAt);
            }
            this.createBy = new User(object.createBy);
            this.updateBy = new User(object.updateBy);
            this.removeBy = new User(object.removeBy);
            this.createdFromIp = object.createdFromIp;
            this.updatedFromIp = object.updatedFromIp;
            if (object.deletedAt) {
                this.deletedAt = new Date(object.deletedAt);
            }
        }
    }

}
