








































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Prop, Vue} from 'vue-property-decorator';
import Loading from "@/components/Loading.vue";

@Component({
    components: {Loading}
})
export default class LastPayment extends Vue {
    @Prop() data: any;
    @Prop() loading: boolean;
    // public data = {
    //     latest: [],
    // };

    // get data(): any {
    //     const latest: any = [];
    //     if (this.in && this.in.latest) {
    //         this.in.latest.forEach((pay: Payment) => {
    //             const p = new Payment(pay);
    //             console.log(p);
    //             latest.push(p);
    //         });
    //         return latest;
    //     }
    //     return [];
    // }

    mounted(): void {
        // this.data.latest = this.in.latest;
        // console.log(this.data);
    }
}
