/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import DocumentRequired from "./DocumentRequired";
import User from "./User";
import SubscriptionDocumentExtend from "@/models/extends/SubscriptionDocumentExtend";
import File from "@/models/File";


export default class SubscriptionDocument extends SubscriptionDocumentExtend {

    public id: number;
    public type: DocumentRequired;
    public isValid?: string;
    public uuid = '';
    public createdAt?: Date;
    public updatedAt?: Date;
    public createBy?: User;
    public updateBy?: User;
    public removeBy?: User;
    public file?: File;
    public createdFromIp? = '';
    public updatedFromIp? = '';
    public deletedAt?: Date;

    constructor(object?: any) {
        super(object);
        if (object) {
            this.id = object.id;
            this.type = (object.type instanceof DocumentRequired) ? object.type : new DocumentRequired(object.type);
            this.isValid = object.isValid;
            this.uuid = object.uuid;
            this.file = (object.file instanceof File) ? object.file : new File(object.file);
            if (object.createdAt) {
                this.createdAt = new Date(object.createdAt);
            }
            if (object.updatedAt) {
                this.updatedAt = new Date(object.updatedAt);
            }

            this.createBy = (object.createBy instanceof User) ? object.createBy : new User(object.createBy);
            this.updateBy = (object.updateBy instanceof User) ? object.updateBy : new User(object.updateBy);
            this.removeBy = (object.removeBy instanceof User) ? object.removeBy : new User(object.removeBy);
            this.createdFromIp = object.createdFromIp;
            this.updatedFromIp = object.updatedFromIp;
            if (object.deletedAt) {
                this.deletedAt = new Date(object.deletedAt);
            }
        }
    }

}
