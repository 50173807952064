

















































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Property from "@/models/Property";
import {api} from "@/services/Api";
import Subscription from "@/models/Subscription";
import Person from "@/models/Person";
import Contact from "@/models/Contact";
import {DateHelperService} from "@/utils/date-helper.service";
import Invoice from "@/models/Invoice";
import Payment from "@/models/Payment";
import Popup from "@/models/Popup";
import Loading from "@/components/Loading.vue";
import SelectPromotion from "@/views/admin/subscription/FormStep/SelectPromotion.vue";
import SelectProperty from "@/views/admin/subscription/FormStep/SelectProperty.vue";
import SelectPerson from "@/views/admin/subscription/FormStep/SelectPerson.vue";
import DocumentRequired from "@/views/admin/subscription/FormStep/UploadDocument.vue";
import Scheduler from "@/views/admin/subscription/FormStep/Scheduler.vue";
import UploadDocument from "@/views/admin/subscription/FormStep/UploadDocument.vue";
import SubscriptionDocument from "@/models/SubscriptionDocument";
import Recap from "@/views/admin/subscription/FormStep/Recap.vue";
import {auth} from "@/services/Auth";
import {StripeCheckout} from "@vue-stripe/vue-stripe";
import CONSTANTS from "@/utils/constant";

@Component({
    components: {
        Recap,
        UploadDocument,
        Scheduler,
        DocumentRequired,
        SelectPerson,
        SelectProperty,
        SelectPromotion,
        Loading,
        Base,
        StripeCheckout,
    }
})
export default class subscriptionForm extends Vue {
    public cancelUrl = process.env.VUE_APP_BASE_URL;
    public successUrl = process.env.VUE_APP_BASE_URL;
    public pk = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

    public steps = [
        {
            id: 1, icon: 'fad fa-home', title: 'Bien', enable: true,
            condition: 'this.subscription.property && this.subscription.property.uuid.length > 0'
        },
        {
            id: 2, icon: 'fad fa-user', title: 'Client', enable: false,
            condition: 'this.subscription.person && this.subscription.person.uuid.length > 0'
        },
        {id: 3, icon: 'fad fa-file', title: 'Document Requis', enable: false, condition: 'true'},
        {
            id: 4,
            icon: 'fad fa-clipboard-list',
            title: 'Echéancier',
            enable: true,
            condition: 'this.isEcheancierValid()'
        },
        {
            id: 5,
            icon: 'fad fa-check',
            title: 'Recap',
            enable: true,
            condition: 'this.isEcheancierValid() && this.isPaymentValid()'
        },
    ];
    public subscription = new Subscription();
    public person = new Person();
    public isCreatingUser = false;
    public isSearching = false;
    public searched = '';
    public selectedProperty?: Property;
    public persons: Array<Person> = [];
    public payment: Payment = new Payment();
    public ht = 0;
    public tva = 0;
    public ttc = 0;
    public edit = false;
    public startDate = '';
    public loadingProperty = false;
    public loading = false;
    public loadingPerson = false;
    public loadingScheduler = false;
    public currentKey = 1;


    public properties: Array<Property> = [];
    public currentStep: any = this.steps[0];

    async loadProperties(): Promise<void> {
        this.loadingProperty = true;
        const res = await api.get('api/property/available');
        res.properties.forEach((item: Property) => {
            this.properties.push(new Property(item));
        });
        this.loadingProperty = false;
    }

    setDocument(document: SubscriptionDocument) {
        const index = this.subscription.documents.findIndex((sd: SubscriptionDocument) => {
            return sd.type.uuid === document.type.uuid;
        });
        if (index !== -1) {
            this.subscription.documents[index].file = document.file;
        } else {
            this.subscription.documents.push(document);
        }
    }

    public removePerson(): void {
        this.$set(this.subscription, 'person', undefined);
        this.$forceUpdate();
        // this.subscription.person = undefined;
    }

    public setpayment(payment: Payment) {
        this.payment = payment;
    }

    selectPerson(person: Person): void {
        this.subscription.person = person;
        this.isSearching = false;
    }

    selectProperty(property: Property): void {
        if (!property) {
            this.subscription.property = undefined;
            this.subscription.duration = 0;
            this.subscription.price = 0;
            this.subscription.recurrent = 0;
            this.subscription.recurrentTtc = 0;
            return;
        }
        if (!property.available) {
            new Popup('Erreur', 'Impossible de selectionner un bien indisponible');
        }
        if (property && property.promotion && property.price) {
            this.selectedProperty = property;
            this.subscription.duration = property.promotion.duration;
            this.subscription.price = property.price;
            if (this.subscription.property !== property) {
                this.subscription.property = property;
            }
            if (property.promotion.duration > 0) {
                this.subscription.recurrent = this.round2(property.price / property.promotion.duration);
                this.subscription.recurrentTtc = this.round2(((this.subscription.recurrent * CONSTANTS.TVA) / 100) + this.subscription.recurrent);
            }
            this.setStartDate();
            if (property.type.documents.length > 0) {
                this.steps[2].enable = true;
            }
            // console.log(property.type.documents);
        }
        this.$forceUpdate();
        this.currentKey++;
    }

    next() {
        if (eval(this.currentStep.condition)) {
            const index = this.steps.findIndex((step) => {
                return step.id > this.currentStep.id && step.enable;
            });
            if (index !== -1) {
                this.currentStep = this.steps[index];
            } else {
                this.save();
            }
        }
    }

    previous() {
        for (let i = (this.steps.length - 1); i >= 0; i--) {
            const step = this.steps[i];
            if (step.id < this.currentStep.id && step.enable) {
                this.currentStep = step;
                return;
            }
        }
    }

    async schedule(): Promise<void> {
        this.setStartDate();
        if (this.subscription.property && this.subscription.property.uuid) {
            const payload = {
                date: this.subscription.startDate,
                duration: this.subscription.duration,
                recurrent: this.subscription.recurrent,
                property: this.subscription.property.uuid,
            };
            const res = await api.post('api/property/get/scheduler', payload);
            if (res.data.invoices) {
                this.subscription.invoices = [];
                res.data.invoices.forEach((invoice: Invoice) => {
                    this.subscription.invoices.push(new Invoice(invoice));
                });
                if (this.subscription.invoices.length > 0) {
                    this.payment.amount = this.subscription.invoices[0].ttc;
                }
            }
        } else {
            new Popup('Formulaire incomplet', 'Il semblerait qu\'aucun bien n\'ai été sélectionné', 'light-warning');
        }
    }

    calculate(): void {

        if (this.subscription && this.subscription.property && this.subscription.property.promotion &&
            this.subscription.property.price) {
            this.subscription.recurrent = Math.floor(this.subscription.property.price / this.subscription.duration);
            if (this.subscription.invoices.length > 0) {
                this.payment.amount = this.subscription.invoices[0].ttc;
            }
            this.payment.type = 'chèque';
        }
    }

    async searchUser(): Promise<void> {
        this.persons = [];
        this.isSearching = true;
        this.loadingPerson = true;
        const res = await api.post('api/person/search', {search: this.searched});
        res.data.persons.forEach((item: Person) => {
            this.persons.push(new Person(item));
        });
        this.loadingPerson = false;
    }

    createUser(): void {
        this.isCreatingUser = true;
        const contact = new Contact();
        this.subscription.person = new Person();
        this.subscription.person.contacts.push(contact);
    }

    async saveUser(): Promise<void> {
        const res = await api.post('api/person/new', {person: this.person});
        if (res.data.status === 'success') {
            this.$bvModal.hide('createUser');
            this.subscription.person = new Person(res.data.person);
            this.$forceUpdate();
        }
    }

    readableDate(date: Date | string | undefined): string {
        return DateHelperService.readable(date);
    }

    isPersonValid(): boolean {
        return this.subscription.person instanceof Person && this.subscription.person.uuid.trim().length > 0
    }

    isEcheancierValid(): boolean {
        return this.subscription.recurrent > 0 && this.subscription.duration > 0
            && this.subscription.startDate !== undefined;
    }

    isPaymentValid(): boolean {
        if (this.payment) {
            if (this.payment.type === 'chèque') {
                if (this.payment.source && this.payment.numero) {
                    // console.log(this.payment.amount > 0 && this.payment.source.trim().length > 0&& this.payment.numero.trim().length > 0);
                    return this.payment.amount > 0 && this.payment.source.trim().length > 0
                        && this.payment.numero.trim().length > 0;
                }
            } else if (this.payment.type.trim().length > 0) {
                // console.log(this.payment.amount > 0);
                return this.payment.amount > 0;
            }
            return false;
        }
        new Popup('Erreur', 'Information de paiement manquante');
        return false;
    }

    async save(): Promise<void> {
        if (this.loading) {
            return;

        }
        if (this.isPaymentValid()) {
            this.loading = true;
            this.$store.commit('load');
            const res = await api.post('api/subscription/new', {
                subscription: this.subscription,
                payment: this.payment
            });
            this.loading = false;
            this.$store.commit('stopLoad');
            if (!res.data) {
                new Popup('Erreur', 'Une erreur a été rencontrée losr de la souscription. Veuillez réessayer plus tard', 'danger');
                return;
            }
            if (res.data.status === 'success') {
                if (!auth.isAdmin() || this.payment.type === 'stripe') {
                    this.checkoutStripe();
                } else {
                    this.$router.push({name: 'souscription.show', params: {uuid: res.data.subscription}});
                }

            }

        } else {
            new Popup('Attention', 'Votre formulaire est incomplet', 'warning');
        }
    }

    checkoutStripe() {
        if (this.$refs.checkoutRef) {
            (this.$refs.checkoutRef as any).redirectToCheckout();
        }
    }

    goToScheduler(): void {
        this.calculate();
        this.schedule();
    }

    setStartDate(): void {
        if (this.subscription.property && this.subscription.property.promotion && !this.subscription.startDate && !this.startDate) {
            const d = this.subscription.property.promotion.billingDay;
            let now = new Date();
            now.setDate(d);
            this.subscription.startDate = now;
            const m = (this.subscription.startDate.getMonth() + 1) <= 9 ? '0' : '';
            this.startDate = this.subscription.startDate.getFullYear() + '-' + m + (this.subscription.startDate.getMonth() + 1);
        } else if (this.subscription.property && this.subscription.property.promotion) {
            const d = this.subscription.property.promotion.billingDay;
            const m = d <= 9 ? '0' + d : d;
            let day = new Date(this.startDate + '-' + m);
            this.subscription.startDate = day;
        }
    }

    async mounted(): Promise<void> {
        if (this.$route.params.hasOwnProperty('uuid')) {
            if (this.$route.params.uuid) {
                this.edit = true;
                await this.loadSub(this.$route.params.uuid);
            }
        }
        if (this.$route.params.propertyUuid) {
            await this.getProperty(this.$route.params.propertyUuid);
        }
        // else {
        // await this.loadProperties();
        // }

        if (auth.isAdmin()) {
            this.steps[1].enable = true;
        } else {
            const res = await api.get('api/person/me');
            if (res.person) {
                this.subscription.person = new Person(res.person);
            } else {
                new Popup('Erreur', 'Nous n\'avons pas pu vous identifiez. Veuillez vous reconnecter.', 'danger');
                auth.logout();
            }
        }
        if (this.subscription.property) {
            if (this.subscription.property.type.documents.length > 0) {
                this.steps[2].enable = true;
            }
        }
        const contact = new Contact();
        this.person.contacts.push(contact);
        this.steps.forEach((step) => {
            try {
                this.next();
            } catch (e) {

            }
        });
        this.calculate();
    }

    async loadSub(uuid: string): Promise<void> {
        const res = await api.get('api/subscription/' + uuid + '/get');
        this.subscription = new Subscription(res.subscription);
        this.payment.amount = this.subscription.invoices[0].ttc;
        const m = (this.subscription.startDate.getMonth() + 1) <= 9 ? '0' : '';
        this.startDate = this.subscription.startDate.getFullYear() + '-' + m + (this.subscription.startDate.getMonth() + 1);
    }

    async getProperty(uuid: string): Promise<void> {
        const res = await api.get('api/property/' + uuid + '/get');
        const property = new Property(res.property);
        // this.subscription.property = property;
        // this.subscription.duration = property.promotion.duration;
        this.properties = [property];
        this.selectProperty(property);
        this.currentKey++;
    }

    round(amount: number): number {
        return Math.round(amount);
    }

    round2(amount: number): number {
        return Math.round(amount * 100) / 100;
    }


}
