/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import Promotion from "./Promotion";
import User from "./User";

export default class Company {

public id: number;
public promotions: Array<Promotion> = [];
public longName? = '';
public shortName = '';
public header? = '';
public footer? = '';
public uuid = '';
public createdAt?: Date;
public updatedAt?: Date;
public createBy?: User;
public updateBy?: User;
public removeBy?: User;
public createdFromIp? = '';
public updatedFromIp? = '';
public deletedAt?: Date;

  constructor (object?: any) {
      if(object){
       this.id= object.id;
       if(object.promotions){
           object.promotions.forEach((occ: any)=>{
               this.promotions.push(new Promotion(occ));
           });
       }
       this.longName= object.longName;
       this.shortName= object.shortName;
       this.header= object.header;
       this.footer= object.footer;
       this.uuid= object.uuid;
       if(object.createdAt){
           this.createdAt= new Date(object.createdAt);
       }
       if(object.updatedAt){
           this.updatedAt= new Date(object.updatedAt);
       }
       this.createBy= new User( object.createBy);
       this.updateBy= new User( object.updateBy);
       this.removeBy= new User( object.removeBy);
       this.createdFromIp= object.createdFromIp;
       this.updatedFromIp= object.updatedFromIp;
       if(object.deletedAt){
           this.deletedAt= new Date(object.deletedAt);
       }
      }
  }

}
