





























































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import User from "@/models/User";
import {api} from "@/services/Api";
import Loading from "@/components/Loading.vue";
import Pagination from "@/components/Pagination.vue";

@Component({
    components: {
        Pagination,
        Loading,
        Base
    }
})    export default class userList extends Vue {
    public users: User[] = [];
    public page = 1;
    public loading = false;
    public searched = '';
    public count = 0;
    public matchCount = 0;
    public total = 0;

    async resetPassword(uuid: string): Promise<void> {
        const res = await api.get('api/user/admin/reset/password/' + uuid);
    }

    async load(): Promise<void> {
        this.loading = true;
        const res = await api.post('api/user/admin/list', {filter: this.$store.state.filter});
        res.data.users.forEach((item: any) => {
            this.users.push(new User(item));
        })
        this.loading = false;
    }

    async mounted(): Promise<void> {
        await this.load();
    }
}
