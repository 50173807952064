import { render, staticRenderFns } from "./clientShow.vue?vue&type=template&id=3d4fe005&scoped=true&"
import script from "./clientShow.vue?vue&type=script&lang=ts&"
export * from "./clientShow.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d4fe005",
  null
  
)

export default component.exports