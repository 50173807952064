import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import './assets/css/style.bundle.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'vue-slider-component/theme/default.css'
import BootstrapVue from "bootstrap-vue";
import {auth} from "@/services/Auth";
import {DateHelperService} from "@/utils/date-helper.service";
import VueApexCharts from 'vue-apexcharts';
import VueSlider from "vue-slider-component";
import 'vue-slider-component/theme/default.css'
import Paginate from 'vuejs-paginate';
// import {StripePlugin} from '@vue-stripe/vue-stripe';
// import CKEditor from '@ckeditor/ckeditor5-vue2';

const stripeOptions = {
    pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT,
    apiVersion: process.env.VUE_APP_API_VERSION,
    locale: process.env.VUE_APP_LOCALE,
};

// Vue.use(StripePlugin, stripeOptions);
Vue.component('paginate', Paginate);
Vue.use(VueApexCharts);
Vue.use(BootstrapVue);
// Vue.use(CKEditor);

Vue.component('apexchart', VueApexCharts);
Vue.component('VueSlider', VueSlider);

Vue.config.productionTip = false;


router.beforeEach((to, from, next) => {
    // console.log('BEFORE');
    if (to.meta && to.meta.requireAuth) {

        if (to.meta.requireAdmin) {
            if (!auth.isAdmin()) {
                next({
                    path: '/login',
                    query: {redirect: to.fullPath}
                });
            }
        }
        if (auth.isConnected()) {
            next();
            return;
        }
        next({
            path: '/login',
            query: {redirect: to.fullPath}
        });
    }
    next();
});

// Vue.directive('click-outside', {
//     bind: function (el, binding, vnode) {
//         el.clickOutsideEvent = function (event) {
//             if (!(el == event.target || el.contains(event.target))) {
//                 vnode.context[binding.expression](event);
//             }
//         };
//         document.body.addEventListener('click', el.clickOutsideEvent)
//     },
//     unbind: function (el) {
//         document.body.removeEventListener('click', el.clickOutsideEvent)
//     },
// });
const currency = {
    name: 'euro',
    symbol: '€',
    left: true,
};
Vue.prototype.money = function (amount: string, symbol = true) {

    amount = '' + Vue.prototype.round2(parseFloat(amount));

    if (amount === undefined) {
        return amount;
    }
    amount = amount.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    if (symbol) {
        if (currency.left) {
            amount = currency.symbol + ' ' + amount;
        } else {
            amount += ' ' + currency.symbol;
        }
    }
    return amount.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

};


Vue.prototype.nFormatter = function (num: number) {
    let si = [
        {value: 1, symbol: ""},
        {value: 1E3, symbol: "k"},
        {value: 1E6, symbol: "M"},
        {value: 1E9, symbol: "G"},
        {value: 1E12, symbol: "T"},
        {value: 1E15, symbol: "P"},
        {value: 1E18, symbol: "E"}
    ];
    let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(2).replace(rx, "$1") + si[i].symbol;
};


Vue.prototype.readableDate = function (date: Date | string, showMinutes = false, minimize = false, showDay = true) {
    return DateHelperService.readable(date, showMinutes, minimize, showDay);
}
Vue.prototype.isAdmin = function () {
    return auth.isAdmin();
}
Vue.prototype.timelapse = function (date?: Date | string | undefined, now?: Date | string | undefined, addPrefix = true, format ?: string): string {
    return DateHelperService.getTimeLapse(date, now, addPrefix, format);
}

Vue.prototype.round = function (amount: number): number {
    return Math.round(amount);
}
Vue.prototype.round2 = function (amount: number): number {
    return Math.round(amount * 100) / 100;
}
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
