




















































































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Property from "@/models/Property";
import {api} from "@/services/Api";
import Promotion from "@/models/Promotion";
import PropertyType from "@/models/PropertyType";
import Map from "@/components/Map.vue";
import Popup from "@/models/Popup";

@Component({
    components: {
        Map,
        Base
    }
})
export default class bienForm extends Vue {
    public property = new Property();
    public promotions: Array<any> = [];
    public promotionSelected = '';
    public mapKey = 1;
    public types: Array<any> = [];

    async loadPromotions() {
        const res = await api.get('api/promotion/admin/list');
        this.promotions = [];
        res.promotions.forEach((promo: any) => {
            const option = {...promo, value: promo, text: promo.title};
            this.promotions.push(option);
        })
    }

    updateTtc() {
        if (this.property.price > 0) {
            this.property.ttc = (this as any).round2(this.property.price * 1.2);
            this.property.tva = (this as any).round2(this.property.price * 0.2);
        }
    }

    updateHt() {
        if (this.property.ttc > 0) {
            this.property.price = (this as any).round2(this.property.ttc / 1.2);
            this.property.tva = (this as any).round2(this.property.price * 0.2);
        }
    }

    updatePromo() {
        if (this.property && this.property.promotion && this.property.promotion.uuid) {
            const promo = this.promotions.find((p) => {
                return p.uuid === (this.property.promotion as any).uuid;
            });
            // console.log(promo);
            if (promo) {
                this.property.promotion = promo;
                this.$forceUpdate();
                this.mapKey++;
                // console.log(this.property.promotion.card);
                return;
            }
        }
        this.property.promotion = new Promotion({uuid: null});
        this.mapKey++;
    }

    async loadTypes() {
        const res = await api.get('api/property/type/list');
        this.types = [];
        res.types.forEach((type: any) => {
            this.types.push({value: type, text: type.title});
        })
    }

    async save() {
        if (this.isValid()) {
            this.$store.commit('load');
            const res = await api.post('api/property/new', this.property);
            if (res.data.status === 'success') {
                this.$router.push({name: 'bien.list'});
            }
            this.$store.commit('stopLoad');
        } else {
            new Popup('Attention', 'Votre formulaire est incomplet', 'warning');
        }
    }

    isValid() {
        return this.property.type && this.property.area > 0 && this.property.promotion && this.property.block
            && this.property.lot && this.property.price && this.property.ttcEnLettre.length > 0
    }

    async mounted(): Promise<void> {

        this.property.type = new PropertyType();
        this.property.promotion = new Promotion();
        this.$forceUpdate();

        this.loadPromotions();
        this.loadTypes();
        if (this.$route.params.hasOwnProperty('uuid')) {
            if (this.$route.params.uuid) {
                await this.load(this.$route.params.uuid);
            }
        }


    }

    async load(uuid: string) {
        this.$store.commit('load');
        const res = await api.get('api/property/' + uuid + '/get');
        this.property = new Property(res.property);
        if (this.property.promotion instanceof Promotion) {
            this.promotionSelected = this.property.promotion.uuid;
        }
        if (!this.property.ttc) {
            this.updateTtc();
        }
        if (!this.property.price) {
            this.updateHt();
        }
        this.$store.commit('stopLoad');
    }
}
