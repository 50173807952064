













































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Prop, Vue} from 'vue-property-decorator';
import Property from "@/models/Property";
import {api} from "@/services/Api";
import Loading from "@/components/Loading.vue";
import Promotion from "@/models/Promotion";
import Swal from "sweetalert2";
import {auth} from "@/services/Auth";
import Pagination from "@/components/Pagination.vue";

@Component({
    components: {Pagination, Loading}
})
export default class bienTab extends Vue {
    public page = 1;
    public properties: Array<Property> = [];
    public loading = false;
    public searched = '';
    public count = 0;
    public matchCount = 0;
    public total = 0;
    @Prop() showHeader?: boolean;
    @Prop() showFooter?: boolean;
    @Prop() personUuid?: string;

    setPage(num: number) {
        this.$store.state.filter.page = num;
        this.load();
    }

    mounted(): void {
        this.load();
    }

    get from() {
        return ((this.$store.state.filter.page - 1) * this.$store.state.filter.perPage) + 1;
    }

    get maxPage() {
        return this.total > 0 ? Math.ceil(this.total / this.$store.state.filter.perPage) : 1;
    }

    get to() {
        return this.from + this.properties.length - 1;
    }

    async load(): Promise<void> {
        this.$store.commit('setInterval');
        if (this.$refs.hasOwnProperty('filter')) {
            (this.$refs.filter as any).hide(true);
        }
        this.properties = [];
        this.loading = true;
        await this.loadProperties();
        this.loading = false;
    }

    async loadProperties() {
        let url = 'api/property/list';
        if (this.personUuid) {
            url += '/' + this.personUuid;
        }
        const res = await api.post(url, {filter: this.$store.state.filter});
        res.data.properties.forEach((item: Property) => {
            this.properties.push(new Property(item));
        });
        this.matchCount = res.data.matchCount;
        this.total = res.data.count;
    }

    async deleteBien(uuid: any) {
        if (!auth.isAdmin()) {
            return;
        }
        Swal.fire({
            title: 'Bien',
            text: "Voulez-vous vraiment supprimer ce bien?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Non, annuler!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await api.delete('api/property/' + uuid + '/delete');
                if (res.data.status === 'success') {
                    this.load();
                    Swal.fire(
                        'Supprimé!',
                        'Bien supprimé avec succès.',
                        'success'
                    );


                }
            }
        })
    }
}
