
























































































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Person from "@/models/Person";
import PaiementTab from "@/views/admin/paiement/paiementTab.vue";
import BienTab from "@/views/admin/biens/bienTab.vue";

@Component({
    components: {
        BienTab,
        PaiementTab,
        Base
    }
})
export default class clientShow extends Vue {
    public person: Person | null = null;

    async load(uuid: string) {
        this.$store.commit('load');
        const res = await api.get('api/person/' + uuid + '/get');
        this.$store.commit('stopLoad');
        this.person = new Person(res.person);

    }
    async resetPassword(uuid: string): Promise<void> {
        const res = await api.get('api/user/admin/reset/password/' + uuid);
    }

    async mounted(): Promise<void> {
        this.$store.commit('resetFilter');
        await this.load(this.$route.params.uuid);
    }
}
