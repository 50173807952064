/* eslint-disable  @typescript-eslint/no-explicit-any */
import Vue from 'vue'
import Vuex from 'vuex'
import User from "@/models/User";
import Popup from "@/models/Popup";
import Person from "@/models/Person";
import Promotion from "@/models/Promotion";

Vue.use(Vuex)

export const FILTER = {
    searched: '',
    promotion: null,
    available: true,
    unavailable: true,
    success: true,
    failed: true,
    ilot: null,
    lot: null,
    context: [],
    budgetMin: null,
    budgetMax: null,
    page: 1,
    perPage: 10,
    payed: true,
    waiting: true,
    penality: true,
    dateMin: null,
    dateMax: null,
};
export default new Vuex.Store({
    state: {
        // USER
        user: null,
        userToken: '',
        userRefreshToken: '',

        // LOADING
        disallowLoading: false,
        isLoading: false,
        loading: false,
        showLoadingLogo: true,
        loadingText: 'Chargement en cours',
        promotions: [],
        filter: JSON.parse(JSON.stringify(FILTER)),
        dateMin: null,
        dateMax: null,
        promotionFilter: null,

        printableContract: '',

        popups: [],

    },
    mutations: {
        setToken(state
                     :
                     any, token
                     :
                     string
        ) {
            if (!token) {
                localStorage.removeItem('token');
            }
            localStorage.setItem('userToken', token);
            state.userToken = token;
        }
        ,
        setRefreshToken(state
                            :
                            any, refreshToken
        ) {
            if (!refreshToken) {
                localStorage.removeItem('userRefreshToken');
            }
            localStorage.setItem('userRefreshToken', refreshToken);
            state.userRefreshToken = refreshToken;
        }
        ,
        setUser(state: any, user: User) {
            if (user) {
                localStorage.setItem('user', JSON.stringify({email: user.email}));
            } else {
                localStorage.removeItem('user');
            }
            state.user = user;
        }
        ,
        setFilter(state: any, filter: any) {
            if (filter) {
                localStorage.setItem('filter', JSON.stringify(filter));
            } else {
                localStorage.removeItem('filter');
            }
            state.filter = filter;
        },
        load(state: any) {
            console.log('loading...');
            state.loading = true;
        },
        stopLoad(state: any) {
            state.loading = false;
        },
        setPrintable(state: any, printable: any) {
            state.printableContract = printable;
        },
        resetFilter(state: any) {
            state.filter = JSON.parse(JSON.stringify(FILTER));

            let min = new Date();
            const additionOfMonths = 6;
            min.setMonth(min.getMonth() - additionOfMonths);

            let max = new Date();
            state.filter.dateMin = min.getUTCFullYear() + '-' + (((min.getUTCMonth() + 1) > 9) ? '' : '0') + (min.getUTCMonth() + 1);
            state.filter.dateMax = max.getUTCFullYear() + '-' + (((max.getUTCMonth() + 1) > 9) ? '' : '0') + (max.getUTCMonth() + 1);
            localStorage.setItem('filter', JSON.stringify(state.filter));
        },
        saveFilter(state: any) {
            localStorage.setItem('filter', JSON.stringify(state.filter));
        },
        setPromotions(state: any, promotions: Promotion[]) {
            state.promotions = promotions;
        },
        logout(state: any) {
            localStorage.removeItem('user');
            localStorage.removeItem('userRefreshToken');
            localStorage.removeItem('token');
            state.user = null;
            state.userToken = null;
        }
        ,
        loading(state
                    :
                    any
        ) {
            state.isLoading = true;
        }
        ,
        stopLoading(state
                        :
                        any
        ) {
            state.isLoading = false;
        }
        ,
        disallowLoading(state
                            :
                            any
        ) {
            state.disallowLoading = true;
        }
        ,
        allowLoading(state
                         :
                         any
        ) {
            state.disallowLoading = false;
        }
        ,
        removePopup(state: any, id) {
            const index = state.popups.findIndex((popup: Popup) => {
                return popup.id === id;
            });
            if (index !== -1) {
                state.popups.splice(index, 1);
            }
        },
        resetDateInterval(state: any) {
            const min = new Date();
            const additionOfMonths = 6;
            min.setMonth(min.getMonth() - additionOfMonths); // For subtract use minus (-)
            const max = new Date();

            state.dateMin = min.getUTCFullYear() + '-' + ((min.getUTCMonth() > 9) ? '' : '0') + min.getUTCMonth();
            state.dateMax = max.getUTCFullYear() + '-' + (((max.getUTCMonth() + 1) > 9) ? '' : '0') + (max.getUTCMonth() + 1);
            state.promotionFilter = null;
        },
        setInterval(state: any) {
            localStorage.setItem('dateMin', JSON.stringify(state.dateMin));
            localStorage.setItem('dateMax', JSON.stringify(state.dateMax));
            localStorage.setItem('promotionFilter', JSON.stringify(state.promotionFilter));
        }
    }
    ,
    actions: {}
    ,
    getters: {
        getUser: (state: any) => () => {
            // if (state.user) {
            //     return state.user;
            // }else if (localStorage.getItem('user')) {
            //     const data = localStorage.getItem('user');
            //     if (data) {
            //         state.user = JSON.parse(data);
            //     }
            //
            // }
            return state.user;
        },
        getFilter: (state: any) => () => {
            try {
                state.filter = JSON.parse(localStorage.getItem('filter') || state.filter);
            } catch (e) {
                state.filter = JSON.parse(JSON.stringify(FILTER));
            }
            // let min = state.filter.dateMin;
            // let max = state.filter.dateMax;
            //
            // const now = new Date();
            // if (typeof min === 'string') {
            //     min = new Date(min);
            // }
            // if (typeof max === 'string') {
            //     max = new Date(max);
            // }
            // if (!min) {
            //     min = new Date();
            //     const additionOfMonths = 6;
            //     min.setMonth(min.getMonth() - additionOfMonths);
            //
            // }
            // if (!max) {
            //     max = new Date();
            // }
            // state.filter.dateMin = min.getUTCFullYear() + '-' + (((min.getUTCMonth() + 1) > 9) ? '' : '0') + (min.getUTCMonth() + 1);
            // state.filter.dateMax = max.getUTCFullYear() + '-' + (((max.getUTCMonth() + 1) > 9) ? '' : '0') + (max.getUTCMonth() + 1);

            localStorage.setItem('filter', JSON.stringify(state.filter));

            return state.filter;
        },
        getInterval: (state: any) => () => {

            state.filter = JSON.parse(localStorage.getItem('filter') || state.filter);
            let min = state.filter.dateMin;
            let max = state.filter.dateMax;
            if (typeof min === 'string') {
                min = new Date(min);
            }
            if (typeof max === 'string') {
                max = new Date(max);
            }
            if (!min) {
                min = new Date();
                const additionOfMonths = 6;
                min.setMonth(min.getMonth() - additionOfMonths);

            }
            if (!max) {
                max = new Date();
            }
            state.filter.dateMin = min.getUTCFullYear() + '-' + (((min.getUTCMonth() + 1) > 9) ? '' : '0') + (min.getUTCMonth() + 1);
            state.filter.dateMax = max.getUTCFullYear() + '-' + (((max.getUTCMonth() + 1) > 9) ? '' : '0') + (max.getUTCMonth() + 1);

        }
    }
    ,
    modules: {}
})
