





























































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Promotion from "@/models/Promotion";
import {api} from "@/services/Api";
import Company from "@/models/Company";
import FileUpload from "@/components/FileUpload.vue";
import File from "@/models/File";
import ProtectedFile from "@/components/ProtectedFile.vue";
import Map from "@/components/Map.vue";
import Swal from 'sweetalert2';
import Popup from "@/models/Popup";

@Component({
    components: {
        Map,
        ProtectedFile,
        FileUpload,
        Base
    }
})
export default class promotionForm extends Vue {
    public key = 1;
    public promotion: Promotion = new Promotion();
    public company: Company = new Company();
    public status = [
        {value: 'En cours', text: 'En cours'},
        {value: 'Fermée', text: 'Fermée'},
    ];
    public companies: Array<any> = [];

    async save(): Promise<void> {
        if (this.isValid()) {
            this.$store.commit('load');
            const res = await api.post('api/promotion/new', this.promotion);
            this.$store.commit('stopLoad');
            if (res.data.status === 'success') {
                if (this.$route.params.uuid) {
                    this.$router.push({name: 'promotion.list'});
                } else {
                    this.$router.push({name: 'contract.editor', params: {uuid: res.data.promotion.uuid}});
                }
            } else {
                Swal.fire(
                    'Erreur!',
                    'La création de la promotion a échouée.',
                    'error'
                );

            }
        } else {
            new Popup('Attention', 'Votre formulaire est incomplet', 'warning');
        }
    }

    async createCompany(): Promise<void> {
        await api.post('api/company/new', {name: this.company.longName});
        this.$bvModal.hide('company');
        // this.loadCompanies();
    }

    setContract(uuid: string) {
        const contract = new File();
        contract.uuid = uuid;
        this.promotion.contractTemplate = contract;
    }

    removeContract() {
        this.promotion.contractTemplate = undefined;
    }

    setCard(uuid: string) {
        const card = new File();
        card.uuid = uuid;
        this.promotion.card = card;
    }

    // async loadCompanies(): Promise<void> {
    //     const res = await api.get('api/company/list');
    //     this.companies = [];
    //     res.companies.forEach((company: any) => {
    //         // console.log(company);
    //         const option = {value: company.uuid, text: company.longName};
    //         this.companies.push(option);
    //     })
    // }

    isValid(): any {
        return this.promotion.title && this.promotion.administrativeFees
            && this.promotion.status && this.promotion.billingDay > 0 && this.promotion.billingDay <= 28 &&
            this.promotion.duration && this.promotion.startDate
    }

    async mounted(): Promise<void> {

        if (this.$route.params.uuid) {
            await this.loadPromo(this.$route.params.uuid);
        }
    }

    async loadPromo(uuid: string) {
        this.$store.commit('load');
        const res = await api.get('api/promotion/' + uuid + '/get');
        this.promotion = new Promotion(res.promotion);
        this.key++;
        this.$store.commit('stopLoad');
    }
}
