





















































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */

import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class SaleState extends Vue {
    @Prop() data: any;

    public strokeColor = "#D13647";
    public max = 0;
    public chartOptions = {};
    public series = [
        {
            name: "",
            data: [0]
        }
    ];
    public labels: string[] = [];

    formatData(): void {
        const monthStat = this.data.subscriptions.monthlyStat;
        if (monthStat) {
            const values: number[] = [];
            monthStat.forEach((stat: { year: string, month: string, count: number }) => {
                if (parseInt(stat.month) < 10) {
                    stat.month = '0' + stat.month;
                }
                this.labels.push((this as any).readableDate(stat.year + '-' + stat.month + '-01', false, true, false));
                values.push(stat.count);
                if (stat.count > this.max) {
                    this.max = stat.count;
                }
            });
            this.series = [
                {
                    name: '',
                    data: values,
                }];
        }
    }

    mounted(): void {
        this.chartOptions = {
            chart: {
                type: "area",
                height: 200,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                },
                dropShadow: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    top: 5,
                    left: 0,
                    blur: 3,
                    color: this.strokeColor,
                    opacity: 0.5
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                type: "solid",
                opacity: 0
            },
            stroke: {
                curve: "smooth",
                show: true,
                width: 3,
                colors: [this.strokeColor]
            },
            xaxis: {
                categories: this.labels,
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                tooltip: {
                    enabled: false
                },
                labels: {
                    show: false,
                    style: {
                        colors: '#B5B5C3',
                        fontSize: "12px",
                        fontFamily: "Poppins"
                    }
                },
                crosshairs: {
                    show: false,
                    position: "front",
                    stroke: {
                        color: '#E5EAEE',
                        width: 1,
                        dashArray: 3
                    }
                }
            },
            yaxis: {
                show: false,
                min: 0,

                labels: {
                    show: false,
                    style: {
                        colors: '#B5B5C3',
                        fontSize: "12px",
                        fontFamily: "Poppins"
                    }
                }
            },
            states: {
                normal: {
                    filter: {
                        type: "none",
                        value: 0
                    }
                },
                hover: {
                    filter: {
                        type: "none",
                        value: 0
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: "none",
                        value: 0
                    }
                }
            },
            tooltip: {
                style: {
                    fontSize: "12px",
                    fontFamily: "Poppins"
                },
                y: {
                    formatter: function (val: number) {
                        return val + " Souscription" + ((val > 1) ? 's' : '');
                    }
                },
                marker: {
                    show: false
                }
            },
            colors: ["transparent"],
            markers: {
                colors: ['FFE2E5'],
                strokeColor: [this.strokeColor],
                strokeWidth: 3
            },
            grid: {
                show: false,
                padding: {
                    left: 0,
                    right: 0
                }
            }
        };
        this.formatData();
    }
}
