/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import Invoice from "@/models/Invoice";
import Payment from "@/models/Payment";
import DocumentRequired from "@/models/DocumentRequired";
import SubscriptionDocument from "@/models/SubscriptionDocument";

export default class SubscriptionExtend {

    public EN_ATTENTE = 'En Attente';
    public EN_COURS = 'En Cours';
    public TERMINE = 'Terminé';
    public ANNULE = 'Annulé';

    public paiement: number;
    public price: number;
    public nextInvoice?: Invoice;
    public unpayedInvoices: Invoice[] = [];
    public invoices: Invoice[] = [];
    public unpayedAmount = 0;
    public transactions: Payment[] = [];
    public documents: Array<SubscriptionDocument> = [];


    constructor(object: any) {
        if (object) {
            this.paiement = object.paiement;
            this.nextInvoice = object.nextInvoice;
            this.unpayedInvoices = object.unpayedInvoices;
            if (this.unpayedInvoices) {
                this.unpayedInvoices.forEach((i: Invoice) => {
                    this.unpayedAmount += i.ttc;
                });
            }
            this.unpayedAmount = Math.ceil(this.unpayedAmount);
            if (object.invoices) {
                object.invoices.forEach((invoice: Invoice) => {
                    if (invoice.payments) {
                        invoice.payments.forEach((payment) => {
                            this.transactions.push(new Payment(payment));
                        })
                    }
                });
                this.transactions.sort((a: Payment, b: Payment) => {
                    if (a.createdAt > b.createdAt)
                        return -1;
                    if (a.createdAt < b.createdAt)
                        return 1;

                    return 0;
                });
            }

        }
    }

    get state(): number {
        if (this.paiement > 0) {
            return Math.ceil((this.paiement * 100) / this.price);
        }
        return 0;
    }

    getDocumentOfType(doc: DocumentRequired): SubscriptionDocument | undefined {
        return this.documents.find((sd: SubscriptionDocument) => {
            return sd.type.id === doc.id && sd.file && sd.file.uuid;
        })
    }

    getDocumentTypeIndex(doc: DocumentRequired): number {
        return this.documents.findIndex((sd: SubscriptionDocument) => {
            return sd.type.id === doc.id;
        })
    }

}
