








































































































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Invoice from "@/models/Invoice";
import Loading from "@/components/Loading.vue";
import Promotion from "@/models/Promotion";
import FilterButton from "@/components/FilterButton.vue";

@Component({
    components: {
        FilterButton,
        Loading,
        Base
    }
})
export default class invoiceList extends Vue {
    public invoices: Invoice[] = [];
    public loading = false;
    public searched = '';
    public count = 0;
    public matchCount = 0;
    public total = 0;
    public payed = 0;
    public payedCount = 0;
    public unpayed = 0;
    public unpayedCount = 0;
    public due = 0;
    public penality = 0;
    public penalityCount = 0;

    public filter = {
        searched: '',
        promotion: null,
        payed: true,
        waiting: true,
        penality: true,
        dateMin: null,
        dateMax: null,
        ilot: null,
        lot: null,
        page: 1,
        perPage: 10,
    };

    initFilter() {
        this.$store.commit('resetDateInterval');
        this.filter = {
            searched: '',
            promotion: null,
            payed: true,
            waiting: true,
            penality: true,
            dateMin: null,
            dateMax: null,
            ilot: null,
            lot: null,
            page: 1,
            perPage: 10,
        };
    }


    setPage(num: number) {
        this.filter.page = num;
        this.load();
    }

    get from() {
        return ((this.filter.page - 1) * this.filter.perPage) + 1;
    }

    get maxPage() {
        return this.matchCount > 0 ? Math.ceil(this.matchCount / this.filter.perPage) : 1;
    }

    get to() {
        return this.from + this.invoices.length - 1;
    }

    async load() {
        if (this.$refs.hasOwnProperty('filter')) {
            (this.$refs.filter as any).hide(true);
        }
        this.$store.commit('saveFilter');
        this.loading = true;
        this.invoices = [];
        const res = await api.post('api/invoice/list', {filter: this.$store.state.filter});
        res.data.invoices.forEach((item: any) => {
            this.invoices.push(new Invoice(item));
        });
        this.loading = false;
        this.matchCount = res.data.matchCount;
        this.total = res.data.count;
        this.payed = res.data.payed;
        this.payedCount = res.data.payedCount;
        this.unpayed = res.data.unpayed;
        this.unpayedCount = res.data.unpayedCount;
        this.due = res.data.due;
        this.penality = res.data.penality;
        this.penalityCount = res.data.penalityCount;
    }


    mounted(): void {
        this.$store.getters.getInterval();
        this.filter.dateMin = this.$store.state.dateMin;
        this.filter.dateMax = this.$store.state.dateMax;
        this.load();
    }
}
