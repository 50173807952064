



































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Popup from "@/models/Popup";
import FileUpload from "@/components/FileUpload.vue";

@Component({
    components: {
        FileUpload,
        Base
    }
})
export default class Config extends Vue {
    public logoKey = 1;
    public logoSrc = '';
    public changePassword = {
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
    };

    public config = {
        name: '',
        contact: '',
        email: '',
        mentionLegal: '',
        footInvoice: '',
        adresse: '',
        logo: '',
    };

    async mounted() {
        const res = await api.get('shared/get/config');
        if (res.status === 'success') {
            const config = res.config;
            if (config.logo) {
                this.logoSrc = process.env.VUE_APP_BACKEND_URL + 'images/' + config.logo;
            }
            this.config = config;
            console.log(this.logoSrc);
            this.logoKey++;
        }
    }

    async modifier() {
        if (this.isValid()) {
            const res = await api.post('api/admin/user/change/password', this.changePassword);
            console.log(res.data);
            if (res.data.status === 'success') {
                new Popup('Succès', 'Votre mot de passe à été mis à jour', 'success', 'fad fa-shield');
            } else {
                new Popup('Erreur', res.data.message, 'danger', 'fad fa-user-lock');
            }
        }
    }

    updateLogoUuid(uuid) {
        this.config.logo = uuid;
    }

    async configuration() {
        if (this.isValidConfig()) {
            const res = await api.post('api/user/admin/update/config', this.config);
            console.log(res.data);
            if (res.data.status === 'success') {
                new Popup('Succès', 'Votre configuration à été mise à jour', 'success');
            }
        } else {
            new Popup('Attention', 'Votre formulaire est incomplet', 'warning');
        }
    }

    isValid() {
        return this.changePassword.oldPassword && this.changePassword.newPassword && this.changePassword.newPassword2
    }

    isValidConfig() {
        return this.config.name && this.config.contact && this.config.mentionLegal
            && this.config.footInvoice && this.config.adresse && this.config.logo
    }
}
