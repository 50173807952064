

























































































































































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Promotion from "@/models/Promotion";
import Swal from 'sweetalert2';
import Loading from "@/components/Loading.vue";

@Component({
    components: {
        Loading,
        Base
    }
})
export default class promotionList extends Vue {
    page = 1;
    public promos: Array<Promotion> = [];
    public archives: Promotion[] = [];
    public promotionLoading = false;
    public searched = '';
    public to: any;
    public count = 0;

    async load(): Promise<void> {
        this.promotionLoading = true;
        this.promos = [];
        this.archives = [];
        const res = await api.post('api/promotion/admin/list', {'search': this.searched});
        res.data.promotions.forEach((promo: any) => {
            this.promos.push(new Promotion(promo));
        })
        res.data.archived.forEach((promo: Promotion) => {
            this.archives.push(promo);
        });
        this.count = res.data.count;
        this.promotionLoading = false;
    }

    showPromotionSubscriptions(promotion: Promotion) {
        this.$store.state.filter.promotion = {uuid: promotion.uuid, title: promotion.title};
        this.$store.commit('saveFilter');
        this.$router.push({name: 'souscription.list'});
    }

    showPromotionPayments(promotion) {
        this.$store.state.filter.promotion = {uuid: promotion.uuid, title: promotion.title};
        this.$store.commit('saveFilter');
        this.$router.push({name: 'payment.list'});
    }

    mounted(): void {
        this.load();
    }

    round(amount: number): number {
        return Math.round(amount);
    }

    async suspendrePromo(uuid: string) {
        Swal.fire({
            title: 'Suspension',
            text: "Voulez-vous suspendre cette promotion ? " +
                "Vous ne pourrez plus effectuer de nouvelles souscriptions sur celle-ci",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, suspendre!',
            cancelButtonText: 'Non, annuler!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await api.get('api/promotion/admin/' + uuid + '/suspendre');
                if (res.status === 'success') {
                    this.load();
                    Swal.fire({
                            title: 'Succès',
                            text: 'Promotion suspendue avec succès.',
                            icon: 'success',
                            timer: 2000
                        }
                    );


                }
            }
        })
    }

    async reprendrePromo(uuid: string): Promise<void> {
        const result = await Swal.fire({
            title: 'Activation', text: "Voulez-vous vraiment réactiver cette promotion ?",
            icon: 'warning', showCancelButton: true, confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', confirmButtonText: 'Oui, Reprendre!', cancelButtonText: 'Non, annuler!',
        });
        if (result.isConfirmed) {
            const res = await api.get('api/promotion/admin/' + uuid + '/reprendre');
            if (res.status === 'success') {
                this.load();
                Swal.fire({
                        title: 'Succès',
                        text: 'Promotion réactivée avec succès.',
                        icon: 'success',
                        timer: 2000
                    }
                );
            }
        }
    }

    async stopPromo(uuid: string): Promise<void> {
        const result = await Swal.fire({
            title: 'Clotûre',
            text: "Voulez-vous vraiment archiver cette promotion ? " +
                "Elle sera supprimée mais pourra être réactivée plus tard depuis les archives",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, Terminer!',
            cancelButtonText: 'Non, annuler!',
        });
        if (result.isConfirmed) {
            const res = await api.get('api/promotion/admin/' + uuid + '/archived');
            if (res.status === 'success') {
                this.load();
                Swal.fire({
                        title: 'Succès',
                        text: 'Promotion archivée avec succès.',
                        icon: 'success',
                        timer: 2000
                    }
                );
            }
        }
    }

    async restorePromo(uuid: string): Promise<void> {
        const result = await Swal.fire({
            title: 'Restaurer',
            text: "Voulez-vous vraiment restaurer cette promotion ? ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, Restaurer!',
            cancelButtonText: 'Non, annuler!',
        });
        if (result.isConfirmed) {
            const res = await api.get('api/promotion/admin/' + uuid + '/restore');
            if (res.status === 'success') {
                this.load();
                Swal.fire({
                        title: 'Succès',
                        text: 'Promotion restaurée avec succès.',
                        icon: 'success',
                        timer: 2000
                    }
                );
            }
        }
    }

}
