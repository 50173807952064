

















































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import router from "@/router";
import {auth} from "@/services/Auth";
// import {api} from "@/services/Api";

@Component({
    components: {}
})
export default class Login extends Vue {
    private username = '';
    private password = '';
    public isLoading = false;
    public reset = false;
    private error = '';


    public async signin(): Promise<void> {
        this.isLoading = true;
        if (!this.username.length) {
            this.error = 'Entrez votre nom d\'utilisateur';
            this.isLoading = false;
        }
        if (!this.password.length) {
            this.error = 'Entrez votre mot de passe';
            this.isLoading = false;
        }
        if (!this.error) {
            await auth.login(this.username, this.password);
            if (!auth.isConnected()) {
                this.isLoading = false;
            }
            // else {
            // router.push({name: 'dashboard'});
            // }
        }
        this.isLoading = false;
        this.$forceUpdate();
    }

    async resetPass(): Promise<void> {
        this.isLoading = true;
        this.$store.commit('load');
        const res = await api.post('shared/reset/password', {email: this.username});
        this.$store.commit('stopLoad');
        this.isLoading = false;
        this.$forceUpdate();
    }

    logout() {
        api.logout();
    }

    get isConnected(): boolean {
        return api.isConnected();
    }

    mounted() {
        auth.logout();
    }
}
