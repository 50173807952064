/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import InvoiceLine from "@/models/InvoiceLine";

export default class InvoiceExtend {
    EN_ATTENTE = 'En attente';
    DUE = 'due';
    EN_RETARD = 'En retard';
    EN_COURS = 'En cours';
    PAYEE = 'Payée';
    TVA = 20;

    public invoiceLines: Array<InvoiceLine> = [];

    public payedAmount = 0;
    public remainingAmount = 0;


    constructor(object?: any) {
        if (object) {
            this.payedAmount = object.payedAmount;
            this.remainingAmount = object.remainingAmount;
        }
    }

    get htWithoutFees() {
        let amount = 0;
        this.invoiceLines.forEach((line: InvoiceLine) => {
            if (line.type !== line.TYPES.FEES) {
                amount += line.amount;
            }
        });
        return amount;
    }

    get ttcWithoutFees() {
        let amount = 0;
        this.invoiceLines.forEach((line: InvoiceLine) => {
            if (line.type !== line.TYPES.FEES) {
                amount += line.amount;
            }
        });
        return amount + Math.round(((amount * this.TVA) / 100) * 100) / 100;
    }

    get htWithoutFeesAndPenalities() {
        let amount = 0;
        this.invoiceLines.forEach((line: InvoiceLine) => {
            if (line.type !== line.TYPES.FEES && line.type !== line.TYPES.PENALITY) {
                amount += line.amount;
            }
        });
        return amount;
    }

    get ttcWithoutFeesAndPenalities() {
        let amount = 0;
        this.invoiceLines.forEach((line: InvoiceLine) => {
            if (line.type !== line.TYPES.FEES && line.type !== line.TYPES.PENALITY) {
                amount += line.amount;
            }
        });
        return amount + Math.round(((amount * this.TVA) / 100) * 100) / 100;
    }

}
