







/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */

import {Component, Prop, Vue} from 'vue-property-decorator';
import File from "@/models/File";
import {api} from "@/services/Api";

@Component({})
export default class ProtectedFile extends Vue {
    @Prop() uuid?: string;
    @Prop() file?: File;
    @Prop() classes: string;
    @Prop() styles: string;
    @Prop() maxWidth: string;
    @Prop() maxHeight: string;
    public type = 'image';
    public res = '';

    mounted(): void {
        if (this.uuid) {
            this.loadFile(this.uuid);
        } else if (this.file) {
            this.loadData(this.file.uuid);
        }
        this.setType();
    }

    setType(): void {
        if (this.file) {
            switch (this.file.type) {
                case 'image/svg+xml': {
                    this.type = 'svg';
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    selectPath(event: any): void {
        this.$emit('path-selected', event.target.id);
    }

    async loadFile(uuid: string): Promise<void> {

        this.loadData(uuid);
    }

    async loadData(uuid: string): Promise<void> {
        this.res = '';
        const res = await api.get('api/file/query/protected/' + uuid);
        this.res = res;
        if (document.querySelector('.protected-file svg')) {
            (document.querySelector('.protected-file svg') as any).style.width = this.maxWidth;
            (document.querySelector('.protected-file svg') as any).style.height = this.maxHeight;
        }
    }


}
