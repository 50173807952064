/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {api} from "@/services/Api";
import store from "@/store";
import axios from "axios";

class UploadService {

    private allowedType = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
    private isUploading = false;
    private nextSlice = null;
    private startChunk = 0;
    private sliceSize = 1024 * 1024 * 2;
    private maxTunnel = 3;
    private currentFiles: any[] = [];
    private xhrTable = [];
    private fileList: any[] = [];
    private currentId ? = '';
    private maxRefreshAttempt = 5;
    private folder ? = null;
    private devBreak = 10;
    private additionalData = {};
    callbacks: any[] = [];
    devCount = 0;
    public endPoint = process.env.VUE_APP_BACKEND_URL + 'api/uploader';

    generateId() {
        const number = Math.random();
        number.toString(36);
        return number.toString(36).substr(2, 9);
    }

    /*
  Vous pouvez fournir un uniqId afin tracker un fichier
   */
    upload(file: any, uniqId ?: string, folderUuid ?: string, additionalData ?: any): string {
        this.additionalData = additionalData;
        const generatedId = this.generateId();
        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();

        const todo = {
            file,
            id: uniqId,
            uniqId: generatedId,
            currentChunk: 0,
            start: 0,
            loaded: 0,
            folderUuid: folderUuid,
            end: this.sliceSize,
            cancelToken: cancelToken,
            source: source,
            sent: 0,
            count: Math.ceil(file.size / this.sliceSize),
            nextSlice: null,
            xhr: null,
            percent: 0,
            refreshAttempt: 0,
        };
        this.fileList.push(todo);
        // if (!this.isUploading) {
        this.launchUpload();
        // }
        return generatedId;
    }


    launchUpload(): void {
        let hasAppend = false;
        while (this.currentFiles.length < this.maxTunnel) {
            if (this.fileList.length === 0) {
                break;
            }
            this.appendTodo();
            hasAppend = true;
        }
        if (!hasAppend && this.currentFiles.length > 0) {
            const todo = this.currentFiles[0];
            this.emit('uploadStart', todo);
            this.send(todo);
        } else {
            this.isUploading = false;
        }
    }

    appendTodo(): void {
        if (this.fileList.length > 0) {
            const todo = this.fileList[0];
            this.currentFiles.push(todo);
            this.fileList.shift();
            this.emit('uploadStart', todo);
            this.send(todo);
        }

    }

    send(todo: any) {
        this.isUploading = true;
        todo.end = todo.start + this.sliceSize;
        if (todo.end > todo.file.size) {
            todo.end = todo.file.size;
        }

        const blob = todo.file.slice(todo.start, todo.end);
        const reader = new FileReader();
        reader.onloadend = async (ev) => {
            if ((ev.target as any).readyState !== FileReader.DONE) {
                return null;
            }
            let data = {
                loaded: todo.loaded,
                chunk: (ev.target as any).result,
                fileName: todo.file.name,
                fileSize: todo.file.size,
                fileType: todo.file.type,
                folderUuid: todo.folderUuid,
                uniqId: todo.uniqId
            };

            data = {...data, ...this.additionalData};

            store.commit('disallowLoading');
            const resp = await api.axios.post(this.endPoint, data, {cancelToken: todo.source.token});
            if (!resp) {
                return;
            }
            const json = resp.data;
            todo.percent = Math.ceil((todo.sent * this.sliceSize * 100) / todo.file.size);
            if (json.success && json.status === 'finished') {
                todo.percent = 100;
                Object.assign(todo, {serverFileId: json.id});
                this.emit('uploadSuccess', todo);
                this.emit('progress', todo);
                const index = this.currentFiles.findIndex((file) => {
                    return file.id === todo.id;
                });
                if (index !== -1) {
                    this.currentFiles.splice(index, 1);
                }
                this.launchUpload();
            }

            if (json.success && json.status === 'loaded') {
                todo.loaded = json.received + 1;
                todo.start = todo.loaded * this.sliceSize;
                todo.sent++;
                this.emit('progress', todo);
                this.emit('chunkSent', todo);
                this.send(todo);
            }

            if (json.success && json.status === 'missing') {
                todo.loaded = json.part;
                todo.start = todo.loaded * this.sliceSize;
                this.send(todo);
            }

            if (json.status === 'error' && todo.refreshAttempt < this.maxRefreshAttempt) {
                todo.refreshAttempt++;
                this.send(todo);
            }

        };
        reader.readAsDataURL(blob);
    }

    emit(type: any, data: any): void {
        if (!this.callbacks.hasOwnProperty(type)) {
            return;
        }
        this.callbacks[type].forEach((callback: any) => {
            callback(data);
        });
    }

    on(type: any, callback: any) {
        if (!this.callbacks.hasOwnProperty(type)) {
            this.callbacks[type] = [];
        }
        if (callback && !this.callbacks[type].includes(callback)) {
            this.callbacks[type].push(callback);
        }
    }

    cancel(fileId: string) {
        const index = this.currentFiles.findIndex((file) => {
            return file.id === fileId;
        });
        if (index !== -1) {
            this.currentFiles[index].source.cancel();
            this.currentFiles.splice(index, 1);
        }
        const index2 = this.fileList.findIndex((file: any) => {
            return file.id === fileId;
        });
        if (index2 !== -1) {
            this.fileList.splice(index2, 1);
        }
        this.launchUpload();
    }

}

export const uploadService = new UploadService();
