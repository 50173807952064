/* eslint-disable  @typescript-eslint/no-explicit-any */
import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';

import store from '@/store';
import router from "@/router";
import Popup from "@/models/Popup";
import Promotion from "@/models/Promotion";
import File from "@/models/File";
import {auth} from "@/services/Auth";

// import User from "@/Model/User";

class Api {
    public axios: AxiosInstance;
    public backendUrl = process.env.VUE_APP_BACKEND_URL;

    constructor() {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        this.axios = axios.create(
            {
                baseURL: this.backendUrl,
                headers,
                data: {}
            },
        );
        this.addLoadingInterceptor(this.axios);
    }

    addLoadingInterceptor(client: AxiosInstance) {
        client.interceptors.request.use((config: AxiosRequestConfig) => {
                if (!store.state.disallowLoading) {
                    store.commit('loading');
                }
                if (store.state.userToken && store.state.userToken.length) {
                    config.headers.Authorization = `Bearer ${store.state.userToken}`;
                }
                return config;
            },
            (error: AxiosError) => {
                console.log('REQUEST ERROR');
                console.log(error);
                if (error.response && error.response.status === 401) {
                    console.log('access denied');
                }
            }
        );

        client.interceptors.response.use((response) => {
            if (response.data.hasOwnProperty('status') && response.data.hasOwnProperty('message')) {
                if (response.data.status === 'error') {
                    new Popup('Erreur', response.data.message, 'danger', 'fad fa-ban');
                } else if (response.data.status === 'unknow') {
                    new Popup('Infos', response.data.message, 'warning', 'fad fa-exclamation-triangle');
                }
            }
            store.commit('stopLoading');
            // Do something with response data
            return response;
        }, async (error) => {
            store.commit('stopLoad');
            // console.log('RESPONSE ERROR');

            if (error.response && error.response.status === 401) {
                if (error.response.data.message === 'Expired JWT Token') {
                    // console.log(error.config);
                    const refreshSuccess = await this.refreshToken();
                    if (!refreshSuccess) {
                        // Swal.fire({
                        //     title: Vue.prototype.Ktrans('Session_expired'),
                        //     text: Vue.prototype.Ktrans('Sorry_you_have_been_disconnected'),
                        //     icon: "warning",
                        //     timer: 5000
                        // });
                    } else {
                        return this.axios.request(error.config);
                    }
                } else {
                    this.logout();
                }

                console.log('access denied');
                return;
            }
            const data = error.response.data;
            if (data.hasOwnProperty('detail')) {
                new Popup('Erreur', data.detail, 'danger');
            } else if (data.hasOwnProperty('message')) {
                new Popup('Erreur', data.message, 'danger');
            } else {
                new Popup('Erreur', 'Une erreur est survenue lors du chargement', 'danger');
            }

            // return Promise.reject(error);
        });
    }


    async get(endpoint: string, raw = false): Promise<any | null> {
        const result = await this.axios.get(this.backendUrl + endpoint);
        if (raw) {
            return result;
        }
        if (result) {
            return result.data;
        }
        return null;
    }

    async download(endpoint: string, name?: string) {
        store.commit('load');
        const res = await this.axios.get(this.backendUrl + endpoint, {responseType: 'blob'});
        store.commit('stopLoad');
        const blob = new Blob([res.data], {type: "application/pdf"});
        let url = window.URL || window.webkitURL;
        let link = url.createObjectURL(blob);
        let a = document.createElement('a');
        if (name) {
            a.setAttribute("download", name);
        }
        a.setAttribute("href", link);
        // a.setAttribute('target', '_blank');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    async downloadFile(file: File) {
        store.commit('load');
        const res = await this.axios.get('shared/download/file/' + file.uuid, {responseType: 'blob'});
        store.commit('stopLoad');
        const blob = new Blob([res.data], {type: file.type});
        let url = window.URL || window.webkitURL;
        let link = url.createObjectURL(blob);
        let a = document.createElement('a');
        a.setAttribute("download", file.realName);
        a.setAttribute("href", link);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    post(endpoint: string, data: any, config?: any): Promise<AxiosResponse> {
        return this.axios.post(this.backendUrl + endpoint, data, config);
    }

    put(endpoint: string, body: any, reqOpts?: any) {
        return this.axios.put(this.backendUrl + endpoint, body, reqOpts);
    }

    patch(endpoint: string, body: any, reqOpts?: any) {
        return this.axios.patch(this.backendUrl + endpoint, body, reqOpts);
    }

    delete(endpoint: string, reqOpts?: any) {
        return this.axios.delete(this.backendUrl + endpoint, reqOpts);
    }

    async login(username: string, password: string) {
        try {
            store.commit('disallowLoading');
            const result = await this.post('api/login_check', {username: username, password: password});
            const data = result.data;
            // console.log(data);
            // return;
            store.commit('setToken', data.token);
            // store.commit('setUser', data.user);
            store.commit('setRefreshToken', data.refresh_token);
            return true;
        } catch (error) {
            if (error.response.status === 401) {
                new Popup('Identifiants incorrects',
                    'Nom d\'utilisateur ou mot de passe incorrect', 'danger', 'fad user-slash');
            }
            return false;
        }
    }


    logout() {
        store.commit('logout');
        // console.log(router);
        if (router.currentRoute.name !== 'login') {
            router.push({name: 'login'});
        }
        // console.log(router.currentRoute.name);
    }

    async refreshToken() {
        try {
            const result = await this.post('api/token/refresh', {refresh_token: store.state.userRefreshToken});
            if (result) {
                const data = result.data;
                store.commit('setToken', data.token);
                store.commit('setUser', data.user);
                store.commit('setRefreshToken', data.refresh_token);
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
            if (error.response.status === 401) {
                // new Popup('danger', Vue.prototype.Ktrans('invalid_credentials'),
                //     Vue.prototype.Ktrans('bad_username_or_password'),
                //     'fad user-slash', '', []);
            }
            return false;
        }
    }


    loadAuth(): any {
        let token = store.state.userToken;
        if (!token) {
            token = localStorage.getItem('userToken');
            if (token === 'null') {
                token = '';
            }
        }
        store.commit('setToken', token);

        let refreshToken = store.state.userRefreshToken;
        if (!refreshToken) {
            refreshToken = localStorage.getItem('userRefreshToken');
            if (token === 'null') {
                refreshToken = '';
            }
        }
        store.commit('setRefreshToken', refreshToken);

        let user = store.state.user;
        if (!user) {
            user = localStorage.getItem('user');
            if (user === 'null') user = null;
            // user = user ? new User(JSON.parse(user)) : null;
        }
        store.commit('setUser', user);

        return {token, refreshToken, user};
    }

    isConnected(): boolean {
        const data = this.loadAuth();
        if (!data) {
            return false;
        }
        return data.token && data.refreshToken && data.user;
    }

    async getPromotions() {
        if (store.state.promotions.length > 0) {
            return store.state.promotions;
        }
        let res: any;
        if (auth.isAdmin()) {
            res = await api.get('api/promotion/admin/list');
        } else {
            res = await api.get('api/promotion/list');
        }
        const promos: Promotion[] = [];
        if (res && res.promotions) {
            res.promotions.forEach((item: Promotion) => {
                promos.push(new Promotion(item));
            });
            store.commit('setPromotions', promos);
        }
        return promos;
    }

}

export const api = new Api();
