












































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Person from '@/models/Person';
import Swal from 'sweetalert2';
import Popup from "@/models/Popup";

@Component({
    components: {
        Base
    }
})
export default class accountShow extends Vue {

    public person = new Person();
    message = "";
    types = [
        {value: 1, title: 'CNI'},
        {value: 2, title: 'Attestation d identité'},
        {value: 3, title: 'Passport'},
        {value: 4, title: 'Autres'}
    ];
    public changePassword = {
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
    };


    async load(): Promise<void> {
        this.$store.commit('load');
        const res = await api.get('api/person/me');
        this.$store.commit('stopLoad');
        if (res.status === 'success') {
            this.person = new Person(res.person);
        }
    }

    async modifier() {
        if (this.isValid()) {
            this.$store.commit('load');
            const res = await api.post('api/user/change/password', this.changePassword);
            this.$store.commit('stopLoad');
            if (res.data.status === 'success') {
                Swal.fire({
                        title: 'Succès',
                        text: 'Mot de passe changé  avec succès.',
                        icon: 'success',
                        timer: 2000
                    }
                );

            }
        }
    }


    async configuration() {
        if (this.isValidConfig()) {
            // this.person.birthday = this.perso
            this.$store.commit('load');
            const res = await api.post('api/person/edit', {person: this.person});
            this.$store.commit('stopLoad');
            if (res.data.status === 'success') {
                Swal.fire({
                        title: 'Succès',
                        text: 'Modification des informations éffectuée avec succès.',
                        icon: 'success',
                        timer: 2000
                    }
                );
            }
        } else {
            new Popup('Attention', 'Votre formulaire est incomplet','warning');
        }
    }

    isValid() {
        return this.changePassword.oldPassword && this.changePassword.newPassword && this.changePassword.newPassword2
    }

    isValidConfig() {
        return this.person.firstName && this.person.lastName && this.person.phone1 && this.person.birthday
            && this.person.address1  && this.person.socialIdentification && this.person.idDeliveryPlace && this.person.idDeliveryDate
        && this.person.lieuDeNaissance && this.person.nationalite
    }

    mounted(): void {
        this.load()
    }
}
