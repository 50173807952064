/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import PersonType from './PersonType';
import Contact from './Contact';
import User from './User';
import PersonExtend from "@/models/extends/PersonExtend";

export default class Person extends PersonExtend {

    public id: number;
    public createdAt: Date;
    public firstName? = '';
    public lastName? = '';
    public email? = '';
    public phone1? = '';
    public phone2? = '';
    public address1? = '';
    public address2? = '';
    public birthday?: Date;
    public lieuDeNaissance = '';
    public legalForm? = '';
    public socialIdentification? = '';
    public nationalite = '';
    public taxIdentification? = '';
    public vatNumber? = '';
    public logo? = '';
    public type?: PersonType;
    public parent?: Person;
    public children: Array<Person> = [];
    public contacts: Array<Contact> = [];
    public identificationType = '';
    public idDeliveryDate ?: Date;
    public idDeliveryPlace = '';
    public uuid = '';
    public disabled?: boolean;
    public updatedAt?: Date;
    public createBy?: User;
    public updateBy?: User;
    public removeBy?: User;
    public createdFromIp? = '';
    public updatedFromIp? = '';

    constructor(object?: any) {
        super(object);
        if (object) {
            this.id = object.id;
            if (object.createdAt) {
                this.createdAt = new Date(object.createdAt);
            }
            this.firstName = object.firstName;
            this.lastName = object.lastName;
            this.email = object.email;
            this.phone1 = object.phone1;
            this.phone2 = object.phone2;
            this.nationalite = object.nationalite;

            if (object.idDeliveryDate) {
                this.idDeliveryDate = new Date(object.idDeliveryDate);
            }

            this.idDeliveryPlace = object.idDeliveryPlace;
            this.lieuDeNaissance = object.lieuDeNaissance;

            this.address1 = object.address1;
            this.address2 = object.address2;
            if (object.birthday) {
                this.birthday = new Date(object.birthday);
            }
            this.legalForm = object.legalForm;
            this.socialIdentification = object.socialIdentification;
            this.taxIdentification = object.taxIdentification;
            this.vatNumber = object.vatNumber;
            this.logo = object.logo;
            this.type = new PersonType(object.type);
            this.parent = new Person(object.parent);
            if (object.children) {
                object.children.forEach((occ: any) => {
                    this.children.push(new Person(occ));
                });
            }
            if (object.contacts) {
                object.contacts.forEach((occ: any) => {
                    this.contacts.push(new Contact(occ));
                });
            }
            this.identificationType = object.identificationType;
            this.uuid = object.uuid;
            this.disabled = object.disabled;
            if (object.updatedAt) {
                this.updatedAt = new Date(object.updatedAt);
            }
            this.createBy = new User(object.createBy);
            this.updateBy = new User(object.updateBy);
            this.removeBy = new User(object.removeBy);
            this.createdFromIp = object.createdFromIp;
            this.updatedFromIp = object.updatedFromIp;
        }
    }

}
