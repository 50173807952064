



































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class SaleLine extends Vue {

    public chartOptions = {};
    public series = [{
        name: 'Montant des souscriptions',
        data: [0]
    }
    ];

    @Prop() data: any;
    public max = 0;
    public labels: string[] = [];

    formatData(): void {
        const monthStat = this.data.subscriptions.monthlyStat;
        if (monthStat) {
            const values: number[] = [];
            monthStat.forEach((stat: { year: string, month: string, count: number, amount: number }) => {
                stat.month = parseInt(stat.month)+'';
                if (parseInt(stat.month) < 10) {
                    stat.month = '0' + stat.month;
                }
                this.labels.push((this as any).readableDate(stat.year + '-' + stat.month + '-01', false, true, false));
                values.push(stat.amount);
                if (stat.count > this.max) {
                    this.max = stat.amount;
                }
            });
            this.series = [
                {
                    name: '',
                    data: values,
                }];
        }
    }

    mounted(): void {
        this.chartOptions = {
            chart: {
                height: 200,
                type: "bar"
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                    borderRadius: 10
                }
            },
            colors: ["#1BC5BD"],
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: this.labels,
            },
            yaxis: {
                labels: {
                    formatter: (value) => {
                        return (this as any).nFormatter(value);
                    }
                },
            },
            tooltip: {
                y: {
                    formatter: (val: number) => {
                        return (this as any).money(val)
                    }
                }
            }
        };
        this.formatData();
    }
}
