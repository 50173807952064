/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import Person from "./Person";

export default class User {

public id?: number;
public email = '';
public password = '';
public role = '';
public person?: Person;

  constructor (object?: any) {
      if(object){
       this.id= object.id;
       this.email= object.email;
       this.password= object.password;
       this.role= object.role;
       this.person= new Person( object.person);
      }
  }

}
