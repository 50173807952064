
























































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Person from "@/models/Person";
import {api} from "@/services/Api";
import Swal from 'sweetalert2';

@Component({
    components: {
        Base
    }
})
export default class clientForm extends Vue {
    public person = new Person();


    async mounted(): Promise<void> {
        if (this.$route.params.uuid) {
            this.$store.commit('load');
            const res = await api.get('api/person/' + this.$route.params.uuid + '/get');
            this.person = new Person(res.person);
            console.log(this.person);
            this.$store.commit('stopLoad');
        }
    }

    async saveUser(): Promise<void> {
        this.$store.commit('load');
        const res = await api.post('api/person/new', {person: this.person});
        if (res.data.status === 'success') {
            this.$router.push({name: 'customer.list'});
        } else {
            Swal.fire(
                'Erreur!',
                'La création du client a échouée.',
                'error'
            );

        }
        this.$store.commit('stopLoad');
    }

}
