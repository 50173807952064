/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import PaymentMethod from "./PaymentMethod";
import Payment from "./Payment";

export default class Receipt {

public id: number;
public receiptNumber? = '';
public paymentMethod?: PaymentMethod;
public payments: Array<Payment> = [];

  constructor (object?: any) {
      if(object){
       this.id= object.id;
       this.receiptNumber= object.receiptNumber;
       this.paymentMethod= new PaymentMethod( object.paymentMethod);
       if(object.payments){
           object.payments.forEach((occ: any)=>{
               this.payments.push(new Payment(occ));
           });
       }
      }
  }

}
