
















































































































































































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Property from "@/models/Property";
import {api} from "@/services/Api";
import Loading from "@/components/Loading.vue";
import Map from "@/components/Map.vue";
import CONSTANTS from "@/utils/constant";

@Component({
    components: {Map, Loading, Base}
})
export default class bienShow extends Vue {
    public property: Property | null = null;
    public loading = false;
    public mapKey = 1;
    public recurrent = 0;

    async load(uuid: string) {
        this.property = null;
        this.loading = true;
        const res = await api.get('api/property/' + uuid + '/get');
        this.property = new Property(res.property);
        if (this.property.subscription) {
            this.recurrent = this.property.subscription.recurrentTtc;
        } else if (this.property.promotion.duration) {
            const ht = (this as any).round2(this.property.ttc / this.property.promotion.duration);
            const tva = (this as any).round2((ht * CONSTANTS.TVA) / 100);
            this.recurrent = ht + tva;
        }
        this.loading = false;
        this.mapKey++;
    }

    async mounted(): Promise<void> {
        if (this.$route.params.uuid) {
            await this.load(this.$route.params.uuid);
        }
    }
}
