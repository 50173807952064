/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import File from "@/models/File";

export default class PromotionExtend {
    public STATUS = {
        'EN_COURS': 'En cours',
        'FERMEE': 'Fermée',
        'ARCHIVE': 'Archivée'
    };
    public paiementHt = 0;
    public paiementTtc = 0;
    public feesHt = 0;
    public feesTtc = 0;
    public recurrentHt = 0;
    public recurrentTtc = 0;

    public recurrentSouscriptionTtc = 0;
    public penalitiesHt = 0;
    public penalitiesTtc = 0;

    public price = 0;
    public ttc = 0;

    public card?: File;
    public contractTemplate?: File;

    constructor(object?: any) {
        if (object) {
            this.paiementHt = object.paiementHt;
            this.paiementTtc = object.paiement;
            this.feesHt = object.feesHt;
            this.recurrentHt = object.recurrentHt;
            this.penalitiesHt = object.penalitiesHt;
            this.price = object.price;
            this.ttc = object.ttc;

            this.feesTtc = object.feesTtc;
            this.recurrentTtc = object.recurrentTtc;
            this.recurrentSouscriptionTtc = object.recurrentSouscriptionTtc;
            this.penalitiesTtc = object.penalitiesTtc;
            this.card = new File(object.card);
            this.contractTemplate = new File(object.contractTemplate);
        }
//
    }
}
