





































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import SaleState from "@/widgets/SaleState.vue";
import Promotion from "@/models/Promotion";
import {api} from "@/services/Api";
import SaleBar from "@/widgets/SaleBar.vue";
import SaleLine from "@/widgets/SaleLine.vue";
import PaymentBar from "@/widgets/PaymentBar.vue";
import LastPayment from "@/widgets/LastPayment.vue";
import LastConnection from "@/widgets/LastConnection.vue";
import Loading from "@/components/Loading.vue";
import Spinner from "@/components/Spinner.vue";
import FilterButton from "@/components/FilterButton.vue";
import PaiementTab from "@/views/admin/paiement/paiementTab.vue";

@Component({
    components: {
        PaiementTab,
        FilterButton,
        Spinner,
        Loading,
        LastConnection,
        LastPayment,
        PaymentBar,
        SaleLine,
        SaleBar,
        SaleState,
        Base,
    }
})
export default class Dashboard extends Vue {
    public loading = false;
    public monthData = {};
    public key = 0;
    public data = {
        subscriptions: {
            paymentAmount: 0,
            paymentCount: 0,
            paymentExpected: 0,
            propertiesPrice: 0,
            propertyCount: 0,
            subscriberCount: 0,
            subscriptionCount: 0,
            subscriptionTotalPrice: 0,
            recurrent: 0,
            recurrentPayed: 0,

        },
        payments: {
            monthlyStat: [],
        }
    };
    public promotions: Promotion[] = [];
    public dateRange: any = null;


    mounted(): void {
        // this.$store.commit('resetFilter');
        // console.log(this.$store.state.filter);
        // console.log(this.$store.state.filter.context);
        if (!this.$store.state.dateMin || !this.$store.state.dateMax) {
            this.$store.getters.getInterval();
        }
        this.dateRange = {
            startDate: this.$store.state.dateMin,
            endDate: this.$store.state.dateMax
        };
        this.loadPromotions();
        this.loadData();
    }

    async loadData(): Promise<void> {
        if (this.$refs.filter) {
            (this.$refs.filter as any).hide();
        }
        this.loading = true;
        this.$store.commit('saveFilter');
        const filter = {...this.$store.state.filter};
        filter.context = ['subscriptions', 'payments'];
        // console.log(filter);
        const res = await api.post('api/admin/get/dashboard/data', {filter: filter});
        if (res.data) {
            if (res.data.data) {

                if (res.data.data.hasOwnProperty('subscriptions')) {
                    this.data.subscriptions = res.data.data.subscriptions;
                }
                if (res.data.data.hasOwnProperty('payments')) {
                    this.data.payments = res.data.data.payments;
                    // const latest: Payment[] = [];
                    // res.data.data.payments.latest.forEach((p: Payment) => {
                    //     latest.push((new Payment(p) as any));
                    // });
                }
            }
        }

        this.$forceUpdate();
        this.key += 1;
        this.loading = false;
    }

    // readableDate(date: Date, showMinutes: boolean, minimized: boolean) {
    //     return DateHelperService.readable(date, showMinutes, minimized);
    // }

    async loadPromotions() {
        const res = await api.get('api/promotion/admin/list');
        res.promotions.forEach((item: Promotion) => {
            this.promotions.push(new Promotion(item));
        });
    }
}
