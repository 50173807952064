/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import Payment from "./Payment";
import InvoiceLine from "./InvoiceLine";
import Subscription from "./Subscription";
import InvoiceDetail from "./InvoiceDetail";
import User from "./User";
import InvoiceExtend from "@/models/extends/InvoiceExtend";

export default class Invoice extends InvoiceExtend {

    public id: number;
    public invoiceNumber?: number;
    public createdAt: Date;
    public date: Date;
    public title? = '';
    public status = '';
    public numero = '';
    public payments: Array<Payment> = [];
    public dueDate?: Date;
    public invoiceLines: Array<InvoiceLine> = [];
    public subscription?: Subscription;
    public ht: number;
    public tva: number;
    public ttc: number;
    public restant: number;
    public details: Array<InvoiceDetail> = [];
    public uuid = '';
    public hasPenality?: boolean;
    public penality = 0;
    public updatedAt?: Date;
    public payedAt?: Date;
    public createBy?: User;
    public updateBy?: User;
    public removeBy?: User;
    public createdFromIp? = '';
    public updatedFromIp? = '';
    public deletedAt?: Date;

    constructor(object?: any) {
        super(object);
        if (object) {
            this.id = object.id;
            this.invoiceNumber = object.invoiceNumber;
            this.numero = object.numero;
            this.hasPenality = object.hasPenality;
            this.penality = object.penality;
            if (object.createdAt) {
                this.createdAt = new Date(object.createdAt);
            }
            if (object.date) {
                this.date = new Date(object.date);
            }
            this.title = object.title;
            this.status = object.status;
            if (object.payments) {
                object.payments.forEach((occ: any) => {
                    this.payments.push(new Payment(occ));
                });
            }
            if (object.dueDate) {
                this.dueDate = new Date(object.dueDate);
            }
            if (object.invoiceLines) {
                object.invoiceLines.forEach((occ: any) => {
                    this.invoiceLines.push(new InvoiceLine(occ));
                });
            }
            this.subscription = new Subscription(object.subscription);
            this.ht = object.ht;
            this.tva = object.tva;
            this.ttc = object.ttc;
            this.restant = object.restant;
            if (object.details) {
                object.details.forEach((occ: any) => {
                    this.details.push(new InvoiceDetail(occ));
                });
            }
            this.uuid = object.uuid;
            if (object.updatedAt) {
                this.updatedAt = new Date(object.updatedAt);
            }
            if (object.payedAt) {
                this.payedAt = new Date(object.payedAt);
            }
            this.createBy = new User(object.createBy);
            this.updateBy = new User(object.updateBy);
            this.removeBy = new User(object.removeBy);
            this.createdFromIp = object.createdFromIp;
            this.updatedFromIp = object.updatedFromIp;
            if (object.deletedAt) {
                this.deletedAt = new Date(object.deletedAt);
            }
        }
    }


}
