/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import Promotion from "./Promotion";
import PropertyType from "./PropertyType";
import Subscription from "./Subscription";
import User from "./User";
import PropertyExtend from "@/models/extends/PropertyExtend";

export default class Property extends PropertyExtend {

    public id: number;
    public promotion: Promotion;
    public type: PropertyType;
    public title = '';
    public description? = '';
    public price = 0;
    public ttc = 0;
    public tva = 0;
    public area: number;
    public lot? = '';
    public block? = '';
    public physicalAddress? = '';
    public pathId? = '';
    public gps = [];
    public svgMapUrl? = '';
    public svgMapAddress? = '';
    public status = '';
    public deleted: boolean;
    public createdAt: Date;
    public available: boolean;
    public subscriptions: Array<Subscription> = [];
    public uuid = '';
    public ttcEnLettre = '';
    public updatedAt?: Date;
    public createBy?: User;
    public updateBy?: User;
    public removeBy?: User;
    public createdFromIp? = '';
    public updatedFromIp? = '';
    public deletedAt?: Date;

    constructor(object?: any) {
        super(object);
        if (object) {
            this.id = object.id;
            this.pathId = object.pathId;
            this.ttc = object.ttc;
            this.tva = object.tva;
            this.ttcEnLettre = object.ttcEnLettre;
            this.promotion = new Promotion(object.promotion);
            this.type = new PropertyType(object.type);
            this.title = object.title;
            this.description = object.description;
            this.price = object.price;
            this.area = object.area;
            this.lot = object.lot;
            this.block = object.block;
            this.physicalAddress = object.physicalAddress;
            this.gps = object.gps;
            this.svgMapUrl = object.svgMapUrl;
            this.svgMapAddress = object.svgMapAddress;
            this.status = object.status;
            this.deleted = object.deleted;
            if (object.createdAt) {
                this.createdAt = new Date(object.createdAt);
            }
            this.available = object.available;
            if (object.subscriptions) {
                object.subscriptions.forEach((occ: any) => {
                    this.subscriptions.push(new Subscription(occ));
                });
            }
            this.uuid = object.uuid;
            if (object.updatedAt) {
                this.updatedAt = new Date(object.updatedAt);
            }
            this.createBy = new User(object.createBy);
            this.updateBy = new User(object.updateBy);
            this.removeBy = new User(object.removeBy);
            this.createdFromIp = object.createdFromIp;
            this.updatedFromIp = object.updatedFromIp;
            if (object.deletedAt) {
                this.deletedAt = new Date(object.deletedAt);
            }
        }
    }

}
