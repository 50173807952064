

















































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Prop, Vue} from 'vue-property-decorator';
import Subscription from "@/models/Subscription";
import DocumentRequired from "@/models/DocumentRequired";
import FileUpload from "@/components/FileUpload.vue";
import SubscriptionDocument from "@/models/SubscriptionDocument";
import File from "@/models/File";

@Component({
    components: {FileUpload}
})
export default class UploadDocument extends Vue {
    @Prop() subscription: Subscription;
    public documents: DocumentRequired[] = [];
    public uploaded = false;


    public next() {
        this.$emit('next');
    }

    public previous() {
        this.$emit('previous');
    }


    setDoc(uuid: string, docUuid: string) {
        this.uploaded = true;
        const dr = this.documents.find((doc: DocumentRequired) => {
            return doc.uuid === docUuid;
        });
        if (dr) {
            const sd = new SubscriptionDocument();
            const file = new File();
            file.uuid = uuid;
            sd.type = dr;
            sd.file = file;
            this.$emit('document', sd);
        }
    }

    mounted(): void {
        if (this.subscription.property && this.subscription.property.type) {
            this.subscription.property.type.documents.forEach((d: DocumentRequired) => {
                this.documents.push(d);
            });
        }
        console.log(this.subscription.documents);
    }
}
