/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import PropertyType from "./PropertyType";
import User from "./User";

export default class DocumentRequired {

public id: number;
public title = '';
public propertyType?: PropertyType;
public uuid = '';
public createdAt?: Date;
public updatedAt?: Date;
public createBy?: User;
public updateBy?: User;
public removeBy?: User;
public createdFromIp? = '';
public updatedFromIp? = '';
public deletedAt?: Date;

  constructor (object?: any) {
      if(object){
       this.id= object.id;
       this.title= object.title;
       this.propertyType= new PropertyType( object.propertyType);
       this.uuid= object.uuid;
       if(object.createdAt){
           this.createdAt= new Date(object.createdAt);
       }
       if(object.updatedAt){
           this.updatedAt= new Date(object.updatedAt);
       }
       this.createBy= new User( object.createBy);
       this.updateBy= new User( object.updateBy);
       this.removeBy= new User( object.removeBy);
       this.createdFromIp= object.createdFromIp;
       this.updatedFromIp= object.updatedFromIp;
       if(object.deletedAt){
           this.deletedAt= new Date(object.deletedAt);
       }
      }
  }

}
