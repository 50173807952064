/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import Invoice from "./Invoice";
import InvoiceLineExtend from "@/models/extends/InvoiceLineExtend";

export default class InvoiceLine extends InvoiceLineExtend {


    public id: number;
    public title = '';
    public type = '';
    public amount: number;
    public invoice?: Invoice;

    constructor(object?: any) {
        super(object);
        if (object) {
            this.id = object.id;
            this.title = object.title;
            this.type = object.type;
            this.amount = object.amount;
            this.invoice = new Invoice(object.invoice);
        }
    }

}
