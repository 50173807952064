/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import Subscription from "@/models/Subscription";
import CONSTANTS from "@/utils/constant";


export default class PropertyExtend {
    public subscription?: Subscription;
    public available = true;

    constructor(object?: any) {
        if (object) {
            if (object.subscriptions) {
                this.available = true;
                object.subscriptions.forEach((sub: Subscription) => {
                    const s = new Subscription(sub);
                    if (sub.status !== s.ANNULE) {
                        this.available = false;
                        this.subscription = s;
                    }
                })
            }
            if (object.price && (!object.tva || !object.ttc)) {
                object.tva = Math.round(((object.price * CONSTANTS.TVA) / 100) * 100) / 100;
                object.ttc = object.price + object.tva;
            }
        }
    }
}
