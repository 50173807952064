/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import Company from "./Company";
import Property from "./Property";
import User from "./User";
import PromotionExtend from "@/models/extends/PromotionExtend";

export default class Promotion extends PromotionExtend {

    static STATUS = {
        'EN_COURS': 'En cours',
        'TERMINE': 'Terminé',
        'SUSPENDU': 'Suspendu',
    };
    public id: number;
    public company?: Company;
    public properties: Array<Property> = [];
    public createdAt: Date;
    public date: Date;
    public endAt: Date;
    public title = '';
    public description? = '';
    public deposit?: number;
    public startDate: Date;
    public duration: number;
    public status = '';
    public administrativeFees?: number;
    public contractExist = false;
    public feeLetter = '';
    public penalityFees?: number;
    public penalityLetter = '';
    public billingDay: number;
    public uuid = '';
    public updatedAt?: Date;
    public createBy?: User;
    public updateBy?: User;
    public removeBy?: User;
    public createdFromIp? = '';
    public updatedFromIp? = '';
    public deletedAt?: Date;
    public paiement = 0;
    public subscriptionCount = 0;
    public propertyCount = 0;
    public owner = '';
    public dayCountBeforePaymentMail = 0;

    constructor(object?: any) {
        super(object);
        if (object) {
            this.paiement = object.paiement;
            this.subscriptionCount = object.subscriptionCount;

            this.contractExist = object.contractExist;
            this.owner = object.owner;
            this.propertyCount = object.propertyCount;
            this.dayCountBeforePaymentMail = object.dayCountBeforePaymentMail;
            this.feeLetter = object.feeLetter;
            this.penalityLetter = object.penalityLetter;
            this.id = object.id;

            this.company = new Company(object.company);
            if (object.properties) {
                object.properties.forEach((occ: any) => {
                    this.properties.push(new Property(occ));
                });
            }
            if (object.createdAt) {
                this.createdAt = new Date(object.createdAt);
            }
            if (object.date) {
                this.date = new Date(object.date);
            }
            if (object.endAt) {
                this.endAt = new Date(object.endAt);
            }
            this.title = object.title;
            this.description = object.description;
            this.deposit = object.deposit;
            if (object.startDate) {
                this.startDate = new Date(object.startDate);
            }
            this.duration = object.duration;
            this.status = object.status;
            this.administrativeFees = object.administrativeFees;
            this.penalityFees = object.penalityFees;
            this.billingDay = object.billingDay;
            this.uuid = object.uuid;
            if (object.updatedAt) {
                this.updatedAt = new Date(object.updatedAt);
            }
            this.createBy = new User(object.createBy);
            this.updateBy = new User(object.updateBy);
            this.removeBy = new User(object.removeBy);
            this.createdFromIp = object.createdFromIp;
            this.updatedFromIp = object.updatedFromIp;
            if (object.deletedAt) {
                this.deletedAt = new Date(object.deletedAt);
            }
        }
    }


}
