
















































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Prop, Vue} from 'vue-property-decorator';
import Loading from "@/components/Loading.vue";
import {api} from "@/services/Api";
import Promotion from "@/models/Promotion";
import Subscription from "@/models/Subscription";

@Component({
    components: {Loading}
})
export default class SelectPromotion extends Vue {
    @Prop() subscription ?: Subscription;
    public selected: any = null;
    public promotions: Promotion[] = [];
    public isLoading = false;

    selectPromotion(promotion: Promotion) {
        if (this.selected != promotion) {
            this.selected = promotion;
        }
        this.$emit('promotion', promotion);
    }

    next() {
        this.$emit('next');
    }

    async loadPromotions(): Promise<void> {
        const res = await api.get('api/promotion/available');
        res.promotions.forEach((promo: Promotion) => {
            this.promotions.push(new Promotion(promo));
        });
    }

    mounted() {
        this.loadPromotions();
    }
}
