










































































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Prop, Vue} from 'vue-property-decorator';
import Promotion from "@/models/Promotion";
import Subscription from "@/models/Subscription";
import {api} from "@/services/Api";
import Loading from "@/components/Loading.vue";
import Property from "@/models/Property";
import Map from "@/components/Map.vue";
import Popup from "@/models/Popup";
import {auth} from "@/services/Auth";

@Component({
    components: {Map, Loading}
})
export default class SelectProperty extends Vue {
    @Prop() subscription: Subscription;
    public promotionSelected: any = null;
    public propertySelected: any = null;
    public promotions: Promotion[] = [];
    public properties: Property[] = [];
    public isLoading = false;
    public useCard = false;
    public mapKey = 0;

    reset() {
        this.promotionSelected = null;
        this.$emit('property', null);
    }

    selectPromotion(promotion: Promotion) {
        if (promotion.duration <= 0) {
            if (auth.isAdmin()) {
                new Popup('Erreur Configuration', 'Aucune durée définie pour cette promotion');
            } else {
                new Popup('En attente', 'Cette promotion est en cours de configuration. Veuillez réessayer plus tard');
            }
            return;
        }
        if (this.promotionSelected != promotion) {
            this.promotionSelected = promotion;
        }
        if (this.promotionSelected.card) {
            this.useCard = true;
        }
        this.loadProperties();
    }

    selectProperty(property: Property) {
        if (this.propertySelected != property) {
            this.propertySelected = property;
        }
        if (this.propertySelected.card) {
            this.useCard = true;
        }
        this.$emit('property', property);
    }

    async loadProperty(uuid: string): Promise<void> {
        const res = await api.get('api/property/' + uuid + '/get');

        if (res.property) {
            const prop = new Property(res.property);
            this.propertySelected = prop;
            this.$emit('property', prop);

        }

    }

    next() {
        this.$emit('next');
    }

    async loadPromotions(): Promise<void> {
        this.isLoading = true;
        const res = await api.get('api/promotion/available');
        res.promotions.forEach((promo: Promotion) => {
            if (promo.contractExist || auth.isAdmin()) {
                this.promotions.push(new Promotion(promo));
            }
        });
        this.isLoading = false;
    }

    async loadProperties(): Promise<void> {
        this.isLoading = true;
        const res = await api.get('api/promotion/available/properties/' + this.promotionSelected.uuid + '/get');

        res.properties.forEach((prop: Property) => {
            this.properties.push(new Property(prop));
        });
        this.isLoading = false;
    }

    mounted() {
        if (this.subscription.property) {
            this.propertySelected = this.subscription.property;
            if (this.subscription.property.promotion) {
                this.promotionSelected = this.subscription.property.promotion;
            }
        }
        this.loadPromotions();
    }
}
