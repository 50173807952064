




































































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Prop, Vue} from 'vue-property-decorator';
import Subscription from "@/models/Subscription";
import Payment from "@/models/Payment";
import {StripeCheckout} from "@vue-stripe/vue-stripe";
import Popup from "@/models/Popup";

@Component({
    components: {
        StripeCheckout
    }
})
export default class Recap extends Vue {
    @Prop() subscription: Subscription;
    @Prop() payment: Payment;
    public cancelUrl = process.env.VUE_APP_BASE_URL;
    public successUrl = process.env.VUE_APP_BASE_URL;
    public pk = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;


    public next() {
        this.$emit('payment', this.payment);
        this.$emit('next');
    }

    public setPaymentType(type: string) {
        this.payment.type = type;
        this.next();
    }

    public previous() {
        this.$emit('previous');
    }

    isEcheancierValid(): boolean {
        return this.subscription.recurrent > 0 && this.subscription.duration > 0
            && this.subscription.startDate !== undefined;
    }

    isPaymentValid(): boolean {
        if (this.payment) {
            if (this.payment.type === 'chèque') {
                if (this.payment.source && this.payment.numero) {
                    // console.log(this.payment.amount > 0 && this.payment.source.trim().length > 0&& this.payment.numero.trim().length > 0);
                    return this.payment.amount > 0 && this.payment.source.trim().length > 0
                        && this.payment.numero.trim().length > 0;
                }
            } else if (this.payment.type.trim().length > 0) {
                // console.log(this.payment.amount > 0);
                return this.payment.amount > 0;
            }
            return false;
        }
        new Popup('Erreur', 'Information de paiement manquante');
        return false;
    }

    mounted() {
        this.payment.amount = this.subscription.invoices[0].ttc;
    }
}
