
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import store from "@/store";
import {api} from "@/services/Api";
import router from "@/router";
import Popup from "@/models/Popup";
import User from "@/models/User";

class Auth {

    async login(username: string, password: string) {
        try {
            store.commit('disallowLoading');
            const result = await api.post('api/login_check', {username: username, password: password});
            const data = result.data;
            store.commit('setToken', data.token);
            store.commit('setRefreshToken', data.refresh_token);
            store.commit('setUser', data.user);
            if (auth.isAdmin()) {
                router.push({name: 'dashboard'});
            } else {
                router.push({name: 'mySubscriptions.list'});
            }
            return true;
        } catch (error) {
            console.log(error);
            // console.log(error.response);
            // if (error.response.status === 401) {
            new Popup('Erreur',
                'Nom d\'utilisateur ou mot de passe incorrect', 'danger', 'fad fa-user-slash');
            // }
            return false;
        }
    }

    loadData() {
        let token = store.state.userToken;
        if (!token) {
            token = localStorage.getItem('userToken');
            if (token === 'null') {
                token = '';
            }
        }
        store.commit('setToken', token);

        let refreshToken = store.state.userRefreshToken;
        if (!refreshToken) {
            refreshToken = localStorage.getItem('userRefreshToken');
            if (token === 'null') {
                refreshToken = '';
            }
        }
        store.commit('setRefreshToken', refreshToken);

        let user = store.state.user;
        if (!user) {
            user = localStorage.getItem('user');
            if (user === 'null') user = null;
            user = user ? new User(JSON.parse(user)) : null;
        }
        store.commit('setUser', user);

        return {token, refreshToken, user};
    }

    isConnected(): boolean {
        const data = this.loadData();
        if (!data) {
            return false;
        }
        return data.token && data.refreshToken;
    }

    isAdmin(): boolean {
        const data = this.loadData();
        if (!data) {
            return false;
        }
        const payload = this.parseJwt(data.token);
        if (payload.hasOwnProperty('roles')) {
            return payload.roles.includes('ROLE_ADMIN');
        }
        new Popup('Erreur', 'L\'authentification a échoué', 'danger', 'fad fa-user');
        this.logout();
        return false;
    }

    logout(): void {
        store.commit('logout');
        localStorage.removeItem('user');
        localStorage.removeItem('userRefreshToken');
        localStorage.removeItem('token');
        if (router.currentRoute.name !== 'login') {
            router.push({name: 'login'});
        }
    }


    parseJwt(token: string): any {
        if (!token) {
            console.log(token + ' is empty');
            return {roles: []};
        }
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
}

export const auth = new Auth();
