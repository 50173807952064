

































































































































































































































































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Subscription from "@/models/Subscription";

@Component({
    components: {
        Base
    }
})
export default class myScheduler extends Vue {

    public sub: Subscription | null = null;

    async mounted(): Promise<void> {
        await this.loadSub("75a435bc-0b7a-4700-9d5f-b5d8bd8eb3ca");
    }

    async downloadFacture(uuid: string): Promise<void> {
        await api.download('shared/download/invoice/' + uuid, 'Facture_' + uuid + '.pdf');
    }

    async downloadEcheancier(): Promise<void> {
        if (this.sub && this.sub.property) {
            let name = '';
            if (this.sub.property.promotion) {
                name += this.sub.property.promotion.title;
                if (this.sub.property.promotion.company) {
                    name += this.sub.property.promotion.company.longName;
                }
            }
            name += this.sub.property.uuid;
            await api.download('shared/download/scheduler/' + this.sub.uuid, 'Echeancier_' + name + '.pdf');
        }
    }

    async loadSub(uuid: string): Promise<void> {
        const res = await api.get('api/subscription/' + uuid + '/get');
        this.sub = new Subscription(res.subscription);
    }

    isSubActive(): boolean {
        if (this.sub) {
            return this.sub.status === this.sub.EN_COURS || this.sub.status === this.sub.TERMINE
        }
        return false;
    }
}
