






















import {Component, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";

@Component({})
export default class LeftMenu extends Vue {
    public website = process.env.VUE_APP_BACKEND_URL;
    get isConnected(): boolean{
        return api.isConnected();
    }
}
