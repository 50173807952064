const CONSTANTS = {
    TVA: 20,
    invoiceStatus: {
        EN_ATTENTE: 'En attente',
        DUE: 'due',
        EN_RETARD: 'En retard',
        PAYEE: 'Payée',
    },
    invoiceLineTypes: {
        FEES: 'Frais',
        PENALITY: 'Pénalité',
        SCHEDULE: 'Echéance',
    },
    paymentStatus: {
        SUCCESS: 'Succès',
        ECHEC: 'Echec',
    },
    promotionStatus: {
        EN_COURS: 'En cours',
        FERMEE: 'Fermée',
        ARCHIVE: 'Archivée'
    },
    documentStatus: {
        EN_ATTENTE: 'En attente',
        VALIDE: 'Validé',
        INVALIDE: 'Invalide'
    },
    subscriptionStatus: {
        EN_ATTENTE: 'En Attente',
        EN_COURS: 'En Cours',
        TERMINE: 'Terminé',
        ANNULE: 'Annulé',
    }
};

export default CONSTANTS;
