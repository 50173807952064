/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import Invoice from "./Invoice";

export default class InvoiceDetail {

public id: number;
public title = '';
public value? = '';
public invoice?: Invoice;

  constructor (object?: any) {
      if(object){
       this.id= object.id;
       this.title= object.title;
       this.value= object.value;
       this.invoice= new Invoice( object.invoice);
      }
  }

}
