
































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class PaymentBar extends Vue {
    public chartOptions = {};
    public series: any[] = [];

    @Prop() data: any;
    public max = 0;
    public labels: string[] = [];

    formatData(): void {
        const monthStat = this.data.payments.monthlyStat;

        if (monthStat) {
            monthStat.forEach((stat: { year: string, month: string, count: number, amount: number, type: string }) => {
                stat.month = parseInt(stat.month) + '';
                if (parseInt(stat.month) < 10) {
                    stat.month = '0' + stat.month;
                }
                const monthName = (this as any).readableDate(stat.year + '-' + stat.month + '-01', false, true, false);
                if (!this.labels.includes(monthName)) {
                    this.labels.push(monthName);
                }
                // values.push(stat.amount);
                if (stat.count > this.max) {
                    this.max = stat.amount;
                }
                const index = this.series.findIndex((type) => {
                    return type.name.trim().toLowerCase() === stat.type.trim().toLowerCase();
                });
                if (index === -1) {
                    this.series.push({name: stat.type.trim().toLowerCase(), data: [stat.amount]});
                } else {
                    this.series[index].data.push(stat.amount);
                }
            });

            // this.series = [
            //     {
            //         name: '',
            //         data: values,
            //     }];
        }
        this.$forceUpdate();
    }


    mounted(): void {
        this.chartOptions = {
            chart: {
                height: 200,
                type: "bar"
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                }
            },
            colors: ["#1BC5BD", "#c5aa32", "#c5183d"],
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: this.labels,
            },
            yaxis: {
                labels: {
                    formatter: (value) => {
                        return (this as any).nFormatter(value);
                    }
                },
            },
            tooltip: {
                y: {
                    formatter: (val: number) => {
                        return (this as any).money(val)
                    }
                }
            }
        };
        this.formatData();
    }
}
