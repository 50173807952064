































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */

import {Component, Prop, Vue} from 'vue-property-decorator';
import File from "@/models/File";
import {api} from "@/services/Api";
import {auth} from '@/services/Auth';

@Component({})
export default class Map extends Vue {
    @Prop() uuid?: string;
    @Prop() file?: File;
    @Prop() classes: string;
    @Prop() styles: string;
    @Prop() maxWidth: string;
    @Prop() maxHeight: string;
    @Prop() allowUnlinkedSelection: boolean;
    @Prop() allowUnavailableSelection: boolean;
    @Prop() disallowAvailableSelection: boolean;
    @Prop() disallowUnavailableSelection: boolean;
    @Prop() disallowSelection: boolean;
    @Prop() showUnavailable: boolean;
    @Prop() showAvailable: boolean;
    @Prop() selected: string;
    public initalSelected = '';
    public type = 'image';
    public svg = '';
    public data: any[] = [];
    user: any;

    mounted(): void {
        if (this.uuid) {
            this.loadFile(this.uuid);
        } else if (this.file) {
            this.loadData(this.file.uuid);
        }
        this.initalSelected = this.selected;

        this.setType();
    }

    setType() {
        if (this.file) {
            switch (this.file.type) {
                case 'image/svg+xml': {
                    this.type = 'svg';
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    selectPath(event: any) {
        if (this.disallowSelection) {
            return;
        }
        const paths = document.querySelectorAll('.map path');
        paths.forEach((path) => {
            path.classList.remove('selected');
        });
        const selected = document.querySelector(".map #" + event.target.id);
        if (selected) {
            if (this.disallowAvailableSelection && selected.classList.contains('available')
                && selected.id !== this.initalSelected) {
                return false;
            }
            if (this.disallowUnavailableSelection && selected.classList.contains('unavailable')
                && selected.id !== this.initalSelected) {
                return false;
            }
            if ((selected.hasAttribute('uuid') && selected.classList.contains('available'))
                || this.allowUnlinkedSelection) {
                selected.classList.add('selected');
                this.$emit('uuid', selected.getAttribute('uuid'));
                this.$emit('path-selected', event.target.id);
            }
        }
    }

    async loadFile(uuid: string) {

        this.loadData(uuid);
    }

    readableDate(date: string): string {
        return this.readableDate(date);
    }

    getCursorPosition(e) {
        e = e || window.event;
        var cursor = {x: 0, y: 0};
        if (e.pageX || e.pageY) {
            cursor.x = e.pageX;
            cursor.y = e.pageY;
        } else {
            cursor.x = e.clientX +
                (document.documentElement.scrollLeft ||
                    document.body.scrollLeft) -
                document.documentElement.clientLeft;
            cursor.y = e.clientY +
                (document.documentElement.scrollTop ||
                    document.body.scrollTop) -
                document.documentElement.clientTop;
        }
        return cursor;
    }

    async loadData(uuid: string) {
        this.svg = '';
        const res = await api.get('api/promotion/map/' + uuid);
        if (res.hasOwnProperty('svg')) {
            this.data = res.data;
            this.svg = res.svg;

            this.$nextTick(() => {
                let availableCount = 0;
                const paths = document.querySelectorAll('.map path');
                const desc = document.querySelector(".description") as any;
                const wrapper = document.querySelector("#kt_wrapper") as any;
                paths.forEach((path) => {
                    path.id = path.id.replaceAll('.', '');
                    path.id = path.id.replaceAll('#', '');
                    path.id = path.id.replaceAll(' ', '');
                    const property = this.data.find((d: any) => {
                        return d.path === path.id;
                    });
                    if (property) {
                        path.setAttribute('uuid', property.uuid);
                        if (!property.available) {
                            if (this.showUnavailable) {
                                path.classList.add('unavailable');
                            }
                            if (auth.isAdmin()) {
                                path.setAttribute('data', property.identity + ' <br>  ' + property.area + ' m² <br> Souscripteur: ' + property.subscriber + ' <br> Prix: ' + (this as any).money(property.price) + ' <br> Souscrit le: ' + property.date + '<br> <span class="badge badge-danger">Indisponible</span>');

                            } else {
                                path.setAttribute('data', property.identity + ' <br> <span class="badge badge-danger">Indisponible</span>');
                            }

                        } else {
                            availableCount++;
                            if (this.showAvailable) {
                                path.classList.add('available');
                            }
                            path.setAttribute('data', property.identity + ' <br>  ' + property.area + ' m² <br> Prix: ' + (this as any).money(property.price) + ' <br> <span class="badge badge-success">Disponible</span>');
                        }
                    } else {
                        if (auth.isAdmin()) {
                            path.setAttribute('data', 'Ce lot n\'est lié à aucun bien');
                        } else {
                            path.setAttribute('data', 'Configuration en cours ...');
                        }
                    }

                    if (path.id === this.selected) {
                        this.selectPath({target: {id: path.id}});
                        path.classList.add('selected');
                    }

                    path.addEventListener('mousemove', (e: any) => {
                        const cursor = this.getCursorPosition(e);
                        if (desc) {
                            desc.classList.add('active');
                            const leftPadding = parseInt(window.getComputedStyle(wrapper).paddingLeft.replace('px', '')) + 40;
                            const topPadding = parseInt(window.getComputedStyle(wrapper).paddingTop.replace('px', '')) + desc.getBoundingClientRect().height + 30;

                            var rect = (document.querySelector("#map-container svg") as any).getBoundingClientRect();
                            var pathRect = path.getBoundingClientRect();
                            let xPos = (e.pageX - leftPadding);
                            let yPos = pathRect.top - (desc.getBoundingClientRect.height / 2);
                            desc.innerHTML = e.target.getAttribute('data');
                            desc.style.left = xPos + 'px';
                            desc.style.top = (e.screenY - (pathRect.height + 20)) + 'px';
                        }
                    });
                    const svg = document.querySelector(".map svg");
                    if (svg) {
                        svg.addEventListener('mouseout', () => {
                            if (desc)
                                desc.classList.remove('active');
                        });
                    }
                    // path.setAttribute('title', 'Disponible');
                    // path.setAttribute('@mouseover', 'selectPath');
                    // path.appendChild(title);
                });
                if (availableCount === 0) {
                    this.$emit('empty');
                }
            });
        } else {
            this.$emit('no-map');
        }

    }
}
