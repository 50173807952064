






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class Stamp extends Vue {
    @Prop() date?: Date;
}
