/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import Subscription from "./Subscription";

export default class PaymentMethod {

public id: number;
public title = '';
public number? = '';
public endDate?: Date;
public subscription?: Subscription;

  constructor (object?: any) {
      if(object){
       this.id= object.id;
       this.title= object.title;
       this.number= object.number;
       if(object.endDate){
           this.endDate= new Date(object.endDate);
       }
       this.subscription= new Subscription( object.subscription);
      }
  }

}
